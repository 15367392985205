import "./styles.scss";
import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";
import { Container } from "react-bootstrap";
import Section1 from "./components/section1";
import { Fade } from "react-awesome-reveal";

function AboutScreen() {
  return (
    <div>
      <WebsiteHeader />
      <div className="aboutScreen">
        <Container>
          <Fade>
            <br />
            <div className="homeScreen-text10 center">
              What is <span className="homeScreen-text2">Digigold?</span>
            </div>
            <br />
            <div className="aboutScreen-box1 center">
              <div className="homeScreen-text3">
                Digigold represents fractional ownership of physical gold that
                is securely stored by the service provider. Customers can
                purchase gold in small quantities (even as little as a fraction
                of a gram), making it accessible to all budget levels.
                Convenient Trading: Through the "LuckyStar Digi Gold" app, users
                can buy and sell gold anytime, anywhere, at real-time market
                prices, similar to trading stocks. Secure Storage: The physical
                gold backing Digigold purchases is stored in highly secure
                vaults, ensuring safety and eliminating the need for customers
                to worry about storage.
              </div>
            </div>
            <br />
            <div className="homeScreen-text10 center">
              Features of{" "}
              <span className="homeScreen-text2">LuckyStar Digi Gold</span>
            </div>
            <br />
            <div className="aboutScreen-box1 center">
              <div className="homeScreen-text3">
                Digital Gold Wallet: The app provides a digital wallet where
                users can keep track of their gold holdings, transaction
                history, and current market value. Instant Transactions: Users
                can buy or sell gold instantly based on real-time market rates,
                offering flexibility and the opportunity to take advantage of
                market fluctuations. Physical Redemption: If customers choose,
                they can convert their Digigold holdings into physical gold,
                which can be delivered or collected from a Lucky Star Gold
                store. Gift Option: The app may offer features that allow users
                to gift Digigold to others, making it an attractive option for
                gifting on special occasions. Easy Access: Available on both
                Android (Play Store) and iOS (Apple Store) platforms, making it
                easy for a wide range of customers to use the service.
              </div>
            </div>
            <br />
            <div className="homeScreen-text10 center">
              Benefits of Using{" "}
              <span className="homeScreen-text2">Digigold</span>
            </div>
            <br />
            <div className="aboutScreen-box1 center">
              <div className="homeScreen-text3">
                Affordability: Unlike traditional gold buying, Digigold allows
                customers to start investing with a minimal amount of money,
                making gold investment more accessible to everyone. Liquidity:
                Digigold provides high liquidity, allowing users to quickly
                convert their digital gold back into cash or physical gold
                whenever needed. Transparency: Real-time updates on gold prices
                and the ability to buy and sell at market rates ensures
                transparency in transactions. Security: The gold is stored in
                secure, insured vaults, providing peace of mind compared to
                storing physical gold at home. Convenience: The app’s
                user-friendly interface makes it easy to manage gold investments
                directly from a smartphone, without visiting a physical store.
              </div>
            </div>
            <br />
            <br />
            <div className="homeScreen-text10 center">
              How to Use{" "}
              <span className="homeScreen-text2">LuckyStar Digi Gold</span>
              &nbsp;App{" "}
            </div>
            <br />
            <div className="aboutScreen-box1 center">
              <div className="homeScreen-text3">
                Download the App: Available on both the Google Play Store for
                Android devices and the Apple Store for iOS devices. Register an
                Account: Users need to create an account with Lucky Star Gold,
                providing necessary personal details and verification. Fund the
                Wallet: Users can fund their digital wallet using various
                payment methods provided by the app. Start Trading: Once the
                wallet is funded, users can start buying and selling Digigold in
                small or large quantities as per their preferences. Monitor
                Portfolio: The app allows users to monitor their gold
                investments, track market trends, and make informed decisions.
              </div>
            </div>
            <br />
            <div className="homeScreen-text10 center">
              Why Choose{" "}
              <span className="homeScreen-text2">LuckyStar Digi Gold</span>
              &nbsp;?{" "}
            </div>
            <br />
            <div className="aboutScreen-box1 center">
              <div className="homeScreen-text3">
                Reputation and Trust: As a well-known brand in the gold market,
                Lucky Star Gold offers reliability and credibility, ensuring
                customer trust in their digital gold products. Zero Making
                Charges: The digital gold model eliminates traditional making
                charges, making it a cost-effective way to invest in gold.
                Innovative Solutions: The introduction of Digigold through a
                dedicated mobile app showcases Lucky Star Gold’s commitment to
                modern, customer-centric solutions. Conclusion Lucky Star Gold's
                "LuckyStar Digi Gold" app offers an innovative and secure way
                for customers to invest in gold digitally, combining the
                benefits of traditional gold investment with the convenience of
                modern technology. Whether for investment, savings, or gifting,
                Digigold provides a versatile solution that aligns with today's
                digital lifestyle.
              </div>
            </div>
            <br />
            <div className="homeScreen-text10 center">
              How Zero Making Charges Work at{" "}
              <span className="homeScreen-text2">Lucky Star Gold</span>
              &nbsp;?{" "}
            </div>
            <br />
            <div className="aboutScreen-box1 center">
              <div className="homeScreen-text3">
                The concept of "zero making charges" in gold jewelry offered by
                Lucky Star Gold Dubai means that customers do not have to pay
                any additional fees for the craftsmanship or labor involved in
                creating the jewelry pieces. Typically, when purchasing gold
                jewelry, the final price includes the cost of gold (based on its
                current market rate), along with making charges, which cover the
                labor, design, and manufacturing costs. These making charges can
                vary depending on the complexity of the design and the
                craftsmanship involved. Transparent Pricing: At Lucky Star Gold,
                customers only pay for the actual weight of the gold used in the
                jewelry, calculated according to the prevailing gold market
                rate. There are no extra fees added for making or designing the
                jewelry, making it a more straightforward and transparent
                purchasing experience.
                <br />
                <b>Cost Savings:</b> By eliminating making charges, customers
                can save a significant amount of money, especially on intricate
                or high-end pieces where making charges would typically be
                higher. This makes purchasing gold jewelry more affordable and
                attractive.
                <br />
                <b>Customer Attraction:</b> Offering zero making charges is a
                marketing strategy to attract more customers, especially in
                competitive markets like Dubai's Gold Souq. It provides a strong
                value proposition, encouraging buyers to choose Lucky Star Gold
                over other jewelry stores that might add hefty making fees.
                <br />
                <b>Encourages Customization:</b> Since Lucky Star Gold also
                offers custom design options, the zero making charge policy can
                encourage customers to opt for personalized jewelry without
                worrying about additional costs typically associated with custom
                designs. <br />
                <b>Higher Volume of Sales:</b> With zero making charges, Lucky
                Star Gold may sell a higher volume of gold jewelry, relying on
                the increased sales volume to maintain profitability. This
                approach is particularly effective in high-traffic areas like
                the Gold Souq in Deira, Dubai, where customer footfall is
                significant.
                <br />
                <b>Quality and Value Assurance:</b> Even with zero making
                charges, Lucky Star Gold ensures that the jewelry is of high
                quality, using pure gold and maintaining craftsmanship
                standards. This reassures customers that they are getting value
                for their money without compromising on the quality of the
                jewelry. <br />
                <b>Example Scenario:</b> If the current gold rate is AED 200 per
                gram and a customer purchases a 10-gram gold necklace, they
                would typically pay AED 2,000 for the gold. In other stores,
                there might be an additional 10-20% added as making charges,
                resulting in a total cost of AED 2,200 to AED 2,400. However, at
                Lucky Star Gold, with zero making charges, the customer only
                pays AED 2,000. This zero-making charge concept appeals to
                budget-conscious customers and those looking for high-quality
                jewelry at transparent and competitive prices.
              </div>
            </div>
            <br />
            <div className="homeScreen-text10 center">
              We are Revolutionizing{" "}
              <span className="homeScreen-text2">Digital Gold</span> Trading in
              the&nbsp; UAE
            </div>
            <br />
            <div className="aboutScreen-box1 center">
              <br />
              <div className="homeScreen-text3">
                Stock Markets are the true indicator of the growth of any
                country's economy. We are bullish on the UAE,
                <br /> and we are bullish on the UAE's prospects to be one of
                the largest economies in the world.
              </div>
              <br />
              <div className="homeScreen-text3">
                We believe that digital gold trading provides a unique
                opportunity for all UAE residents to
                <br /> participate in the growth story of the UAE. We are
                enabling this with LSG.
              </div>
              <br />
              <div className="homeScreen-text3">
                As financial services become more accessible, there is now a
                large set of UAE residents who are financially aware and
                literate. They value time and seek high-quality products and
                services. Most investment platforms available today are more or
                less similar to each other and have not evolved with time. While
                both traders and investors have gotten smart about how they make
                money and build wealth, they have continued to use the same
                products, features, and platforms that have been available for
                years with little or no innovation. We plan to change that with
                LSG – a technology-led platform built for super traders and
                long-term investors.
              </div>
            </div>
          </Fade>
        </Container>
      </div>
      <Section1 />
      <WebsiteFooter />
    </div>
  );
}

export default AboutScreen;
