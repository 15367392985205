import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";

import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section1 from "./components/section1";
import Section4 from "./components/section4";
import Section5 from "./components/section5";

import { GET } from "../../../utlis/apiCalls";
import { useSelector } from "react-redux";
import API from "../../../config/api";

function HomeScreen() {
  const User = useSelector((state: any) => state?.auth?.user);
  const [isLoading, setIsLoading] = useState(false);
  const [type, SetType] = useState<string>("buy");
  const [tradeData, setTradeData] = useState();

  useEffect(() => {
    getUserTrade();
  }, []);

  const getUserTrade = async () => {
    setIsLoading(true);
    let url = API.TRADING_USERS_LIST;
    let query = `?order=DESC&page=${1}&take=${8}`;

    try {
      const response: any = await GET(url + User.id + query, null);
      if (response?.status) {
        setTradeData(response?.data?.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <div className="app-HomeScreen">
      <Container fluid={false}>
        <Row className="app-HomeScreen-Box1 g-0  w-100">
          <Col md={12} className="h-100">
            <Section1 type={type} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            md={{ order: 1, span: 6 }}
            lg={{ order: 1, span: 4 }}
            xs={{ order: 2, span: 12 }}
          >
            <Section3
              refreshTrade={getUserTrade}
              type={type}
              setType={SetType}
            />
          </Col>
          <Col
            md={{ order: 3, span: 12 }}
            lg={{ order: 2, span: 3 }}
            xs={{ order: 3, span: 12 }}
          >
            <Section4 data={tradeData} loading={isLoading} />
          </Col>
          <Col
            md={{ order: 2, span: 6 }}
            lg={{ order: 3, span: 5 }}
            xs={{ order: 1, span: 12 }}
          >
            <Section2 />
            <Section5 />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeScreen;
