import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function Section5() {
  const { t } = useTranslation();
  return (
    <div className="app-HomeScreen-Section5">
      <div className="app-HomeScreen-Section5-txt2">
        {t("live_gold_ratechart")}
      </div>
      <iframe
        src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_2f642&symbol=FOREXCOM%3AXAUUSD&interval=60&hidesidetoolbar=1&symboledit=1&saveimage=0&toolbarbg=f1f3f6&studies=%5B%5D&hideideas=1&theme=Dark&style=1&timezone=Etc%2FUTC&hidevolume=1&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&utm_medium=widget&utm_campaign=chart&utm_term=FOREXCOM%3AXAUUSD"
        width="100%"
        height="300"
      ></iframe>
    </div>
  );
}

export default Section5;
