import React, { useState } from "react";
import { Button, Form, Input, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Col, Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPhone } from "react-icons/fa6";
import { ImLocation } from "react-icons/im";
import { SiMinutemailer } from "react-icons/si";
import { POST } from "../../../utlis/apiCalls";
import API from "../../../config/api";

function ContactSection1() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (val: any) => {
    try {
      setIsLoading(true);
      const response: any = await POST(API.CONTACT_CREATE, val);
      if (response?.status) {
        form.resetFields();
        notificationApi.success({
          duration: 8,
          placement: "top",
          message: t("thank_you_for_reaching_out"),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="contactScreen-section1">
      {contextHolder}
      <Container>
        <div className="contactScreen-text1 center">
          <span className="contactScreen-text2"> Contact </span>Us
        </div>
        <br />
        <Row>
          <Col sm={6} xs={12}>
            <Row>
              <Col sm={2} xs={12}></Col>
              <Col sm={10} xs={12}>
                <Form form={form} onFinish={submitHandler}>
                  <div className="contactScreen-text4">Name</div>
                  <div className="contactScreen-formInput">
                    <Form.Item
                      name="name"
                      rules={[{ required: true, message: t("required") }]}
                    >
                      <Input size="large" bordered={false} />
                    </Form.Item>
                  </div>
                  <div className="contactScreen-text4">Email</div>
                  <div className="contactScreen-formInput">
                    <Form.Item
                      name="email"
                      rules={[{ required: true, message: t("required") }]}
                    >
                      <Input size="large" type="email" />
                    </Form.Item>
                  </div>
                  <div className="contactScreen-text4">Mobile Number</div>
                  <div className="contactScreen-formInput">
                    <Form.Item
                      name="phone"
                      rules={[{ required: true, message: t("required") }]}
                    >
                      <Input size="large" type="number" />
                    </Form.Item>
                  </div>
                  <div className="contactScreen-text4">Message</div>
                  <div className="homeScreen-formInput">
                    <Form.Item
                      name="message"
                      rules={[{ required: true, message: t("required") }]}
                    >
                      <TextArea size="large" rows={4} />
                    </Form.Item>
                  </div>
                  <Row>
                    <Col sm={6} xs={12}></Col>
                    <Col sm={6} xs={12}>
                      <Button
                        block
                        htmlType={"submit"}
                        style={{
                          height: 50,
                          border: 0,
                          backgroundColor: "#00a56e",
                        }}
                        loading={isLoading}
                      >
                        {t("submit")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <br />
              </Col>
            </Row>
          </Col>
          <Col sm={1} xs={12}></Col>
          <Col sm={5} xs={12}>
            <div>
              <div className="contactScreen-section1-title">
                <div>
                  <ImLocation />
                </div>
                <div>{t("address")}</div>
              </div>
              <div className="contactScreen-text5">
                {t("LSG_Digital_Gold")}
                <br /> {t("Dalmook_3_Building_Shop_No5")} <br />
                {t("Near_Women_Museum_Gold_Souk")}
                <br /> Dubai, UAE
              </div>
            </div>
            <div>
              <div className="contactScreen-section1-title">
                <div>
                  <SiMinutemailer />
                </div>
                <div>{t("email_address")}</div>
              </div>
              <div className="contactScreen-text5">sales@luckystargold.com</div>
            </div>
            <div>
              <div className="contactScreen-section1-title">
                <div>
                  <FaPhone />
                </div>
                <div>{t("contact_us")}</div>
              </div>
              <div className="contactScreen-text5">
                +971 56 508 4747
                <br />
                +971 56 508 4242
                <br />
                +971 56 508 4242
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactSection1;
