import { ConfigProvider, Layout } from "antd";
import "./styles.scss";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import AppHeader from "./header";
import SiderScreen from "./sider";

import KycScreen from "../screens/KycScreen";
import HomeScreen from "../screens/homescreen";
import NotificationScreen from "../screens/notificationScreen";
import RedeemScreen from "../screens/redeemScreen";
import SipScreen from "../screens/sipScreen";
import SipDetails from "../screens/sipScreen/details";
import TradeScreen from "../screens/tradeScreen";
import TransactionLogScreen from "../screens/transactionLogScreen";
import ProfileScreen from "../screens/profilesScreen";
import PaymentSuccess from "../screens/walletScreen.tsx/paymentRedirect/success";
import SendGift from "../screens/sendGift";
import WalletScreen from "../screens/walletScreen.tsx";
import MarqueeComponent from "../../components/marketInfo";
import {
  default as PaymentScreen,
  default as StripeForm,
} from "../screens/walletScreen.tsx/stripePayment";
import BankInfo from "../screens/bankDetails";
import UserThemeObj from "./components/userTheme";

function AppRoutes() {
  return (
    <ConfigProvider theme={UserThemeObj}>
      <Layout>
        <MarqueeComponent />
        <Layout>
          <Layout.Sider
            width={230}
            style={{ backgroundColor: " #000" }}
            className="app-sidebar-desktop"
          >
            <SiderScreen onChange={() => {}} />
          </Layout.Sider>
          <Layout.Content
            style={{
              backgroundImage: "radial-gradient(#000000c3, #000, #000, #000)",
              height: "100vh",
            }}
          >
            <AppHeader />
            <div className="app-Content">
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/home" element={<HomeScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
                <Route
                  path="/transactionLog"
                  element={<TransactionLogScreen />}
                />
                <Route path="/redeem" element={<RedeemScreen />} />
                <Route path="/sendGift" element={<SendGift />} />
                <Route path="/kyc" element={<KycScreen />} />
                <Route path="/sip" element={<SipScreen />} />
                <Route path="/sipDetails/:id" element={<SipDetails />} />
                <Route path="/wallet" element={<WalletScreen />} />
                <Route
                  path="/wallet/checkoutScreen"
                  element={<PaymentScreen />}
                />
                <Route path="/BankDetails" element={<BankInfo />} />
                <Route path="/notification" element={<NotificationScreen />} />
              </Routes>
            </div>

            {/* <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/notification" element={<NotificationScreen />} />
            <Route path="/transactionLog" element={<TransactionLogScreen />} />
            <Route path="/redeem" element={<RedeemScreen />} />
            <Route path="/tradeDetails/:id" element={<TradeScreen />} />
            <Route path="/sip" element={<SipScreen />} />
            <Route path="/sipDetails/:id" element={<SipDetails />} />
            <Route path="/StripeForm/" element={<StripeForm />} />
            <Route path="/paymentSuccess/" element={<PaymentSuccess />} />
            <Route path="/checkoutScreen/" element={<PaymentScreen />} />
          </Routes> */}
          </Layout.Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

export default AppRoutes;
