import { List, Pagination } from "antd";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";

import API from "../../../../config/api";
import { GET } from "../../../../utlis/apiCalls";
import HistoyListItems from "./histoyListItems";

function HistoryDataTable(props: any) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactionLogs, setTransactionLogs] = useState<any>();
  const [meta, setMeta] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const currentInfo = useSelector((state: any) => state?.currencyInfo?.current);
  useEffect(() => {
    getTransactionLogs(page, pageSize);
  }, []);
  const getTransactionLogs = async (p: any, t: any) => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.SIP_HISTORY_SIP_BY_ID +
          Number(props?.sipId) +
          `?order=DESC&page=${p}&take=${t}`,
        null
      );
      console.log("response", response);
      if (response?.status) {
        setTransactionLogs(response.data.data);
        setMeta(response?.data?.meta);
      } else {
        setTransactionLogs([]);
        setMeta([]);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (page: number) => {
    setPage(page);
    getTransactionLogs(page, pageSize);
  };
  const handlePageSize = (current: number, page: number) => {
    setPage(current);
    setPageSize(page);
    getTransactionLogs(current, page);
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={transactionLogs}
        bordered={false}
        loading={isLoading}
        renderItem={(item: any) => <HistoyListItems data={item} />}
      />
      <div dir="LTR">
        <Pagination
          current={page || 1}
          pageSize={pageSize || 10}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          onShowSizeChange={handlePageSize}
          defaultPageSize={pageSize || 10}
          disabled={false}
          hideOnSinglePage={pageSize < 10 ? true : false}
          onChange={handlePageChange}
          style={{ marginTop: "20px", textAlign: "center", color: "#ffffff" }}
          nextIcon={<IoIosArrowDropright color="#ffffff" size={20} />}
          prevIcon={<IoIosArrowDropleft color="#ffffff" size={20} />}
          jumpPrevIcon={
            <MdOutlineKeyboardDoubleArrowLeft color="#ffffff" size={20} />
          }
          jumpNextIcon={
            <MdOutlineKeyboardDoubleArrowRight color="#ffffff" size={20} />
          }
        />
      </div>
    </>
  );
}

export default HistoryDataTable;
