export type KycRequestBody = {
  type: string;
  name: string;
  code: string;
  code1: string;
  code2: string;
  companyName: string;
  mobile: string;
  email: string;
  address: string;
  city: string;
  pinCode: string;
  vatNumber: string;
  natureOfBusiness: string;
  isLogisticServiceRequired: boolean;
  primaryPartnerName: string;
  primaryPartnerMobile: string;
  secondaryPartnerName: string;
  secondaryPartnerMobile: string;
  bankName: string;
  branch: string;
  bankAccountName: string;
  accountNumber: string;
  ifscCode: string;
  authorisedPersonPrimary: string;
  authorisedPersonSecondary: string;
  authorisedPersonTertiary: string;
  authorisedPersonQuaternary: string;
  companyPan: string;
  vatCertificate: string;
  authorisedPersonPrimaryImage: string;
  authorisedPersonSecondaryImage: string;
};

export enum statusType {
  pending = "pending",
  accept = "accepted",
  reject = "rejected",
  not_submitted = "not_submitted",
}
