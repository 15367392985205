import { Card, Table } from "antd";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function DataTable(props: any) {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "PHONE",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (record: any) => {
        return <div className="table-Txt">{`${record}`}</div>;
      },
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (record: any) => {
        return <div className="table-Txt">{record || "N/A"}</div>;
      },
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      render: (record: any) => {
        return <div className="table-Txt">{record || "N/A"}</div>;
      },
    },
    {
      title: "VIEW",
      dataIndex: "view",
      key: "view",
      render: (text: any, record: any) => (
        <MdOutlineRemoveRedEye
          size={25}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/admin/customerdetails/${record.id}`)}
        />
      ),
    },
  ];
  return (
    <>
      <Card>
        <Table
          loading={props?.isLoading}
          columns={columns}
          dataSource={props?.data}
          pagination={false}
          className="commen-table-custom-scrollbar"
          size="small"
        />
      </Card>
    </>
  );
}

export default DataTable;
