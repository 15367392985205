import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCmNK72UHsaXdCTyI5kSZSznou7PW8qp-c",
  authDomain: "digigold-dfc5b.firebaseapp.com",
  projectId: "digigold-dfc5b",
  storageBucket: "digigold-dfc5b.appspot.com",
  messagingSenderId: "195395231947",
  appId: "1:195395231947:web:160271ce6c9e922ffebb4e",
  measurementId: "G-S4QT7DY461",
};
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const GoogleProvide = new GoogleAuthProvider();
export { auth, GoogleProvide };
