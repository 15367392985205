import { Card, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  StatusColor,
  TypeColor,
  changeMetalTypeFormat,
  metalColor,
} from "../../../../shared/helpers/functions";

function DataTable(props: any) {
  const navigate = useNavigate();
  const getPaymentType = (type: string) => {
    switch (type.toLowerCase()) {
      case "card":
        return "Card";
      case "wallet":
        return "Wallet";
      case "cash":
        return "Cash";
      case "cash_pickup":
        return "Cash pickup";
      case "payment_at_shop":
        return "Payment at shop";
      case "cash_delivery":
        return "Cash delivery";
      case "add_by_shop":
        return "Add by shop";
      default:
        return "Unknown";
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "user",
      key: "name",
      render: (text: any) => <span>{text?.name}</span>,
    },
    {
      title: "Deposite/Withdrawal",
      dataIndex: "type",
      key: "type",
      render: (text: any) => (
        <span>
          <Tag color={TypeColor(text)}>{text}</Tag>
        </span>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "user",
      key: "user",
      render: (text: any) => text?.phoneNumber,
    },
    {
      title: "Amount (AED)",
      dataIndex: "amount",
      key: "amount",
      render: (text: any) => <span>{text?.toFixed(2)}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (text: any) => <span>{getPaymentType(text)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => (
        <span style={{ textTransform: "capitalize" }}>
          <Tag color={StatusColor(text)}>{text}</Tag>
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "view",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <MdOutlineRemoveRedEye
          size={25}
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/admin/walletDetails/${record.id}`, {
              state: { item: record },
            })
          }
        />
      ),
    },
  ];

  return (
    <>
      <Card className="commen-table-Card">
        <Table
          loading={props?.isLoading}
          columns={columns}
          dataSource={props?.allTrade}
          pagination={false}
          className="commen-table-custom-scrollbar"
          size="small"
        />
      </Card>
    </>
  );
}

export default DataTable;
