import React from "react";
import BArChart from "./chart";

function Section4() {
  return (
    <div className="app-WalletScreen-Section4">
      <BArChart />
    </div>
  );
}

export default Section4;
