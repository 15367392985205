import { Card, Modal, Radio, Space, notification } from "antd";
import { useEffect, useState } from "react";
import { GET } from "../../../../utlis/apiCalls";
import API from "../../../../config/api";
import { useSelector } from "react-redux";
import { metalType } from "../../../../shared/types/metalTypes";
import { BsBank, BsCashStack } from "react-icons/bs";
import { IoWalletOutline } from "react-icons/io5";
import { FaCreditCard, FaShop } from "react-icons/fa6";
import { Col, Row } from "react-bootstrap";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function PaymentTypeModal(props: any) {
  const [paymentType, setPaymentType] = useState("wallet");
  const [notificationApi, contextHolder] = notification.useNotification();
  const Auth = useSelector((state: any) => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [walletCash, setWalletCash] = useState<any>();
  const [bankDetailsModalOpen, setBankDetailsModalOpen] =
    useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    LoadWallet();
  }, []);

  const LoadWallet = async () => {
    try {
      setIsLoading(true);
      let userId = props?.admin ? props?.userId : Auth?.user?.id;
      const response: any = await GET(API.GET_WALLET + userId, null);
      if (response?.status) {
        const wallet = response?.data;
        const cash = wallet?.find(
          (item: any) => metalType.cash === item?.metal_type
        );
        setWalletCash(cash);
      } else {
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmit = async () => {
    if (paymentType !== "bank" && paymentType !== "card") {
      if (paymentType === "wallet") {
        if (walletCash) {
          if (
            walletCash?.balance >=
            props?.form?.getFieldValue("amount") /
              props?.currentInfo?.exchange_rate
          ) {
            props?.form?.setFieldValue("payment_type", paymentType);
            props?.onFinish();
            props?.onCancel();
          } else {
            let messages: any = props?.admin
              ? t("insufficient_balance_in_wallet_payment1")
              : t("insufficient_balance_in_wallet_payment");
            notificationApi.warning({
              message: messages,
            });
          }
        } else {
          notificationApi.warning({
            message: t("wallet_for_cash_not_found"),
          });
        }
      } else {
        props?.form?.setFieldValue("payment_type", paymentType);
        props?.onFinish();
        props?.onCancel();
      }
    } else if (paymentType === "bank") {
      setBankDetailsModalOpen(true);
    } else if (paymentType === "card") {
      props?.form?.setFieldValue("payment_type", paymentType);
      props?.onFinish();
      props?.onCancel();
    } else {
      notificationApi.info({
        message: t("sorry_payment_option_unavailable"),
      });
    }
  };
  const balance = () => {
    return (
      walletCash?.balance * props?.currentInfo?.exchange_rate -
      props?.form?.getFieldValue("amount")
    );
  };
  return (
    <>
      {contextHolder}
      <Modal
        open={props?.open}
        okText={t("submit")}
        cancelText={t("cancel")}
        onOk={handleSubmit}
        onCancel={props?.onCancel}
        okButtonProps={{
          loading: isLoading,
        }}
        cancelButtonProps={{ loading: isLoading }}
      >
        <div className="app-HomeScreen-txt5">{t("select_payment_type")}</div>
        <br />
        <Radio.Group
          onChange={(e: any) => {
            setPaymentType(e?.target?.value);
          }}
          defaultValue={paymentType}
          value={paymentType}
        >
          <Space direction="vertical" style={{ paddingLeft: 20 }}>
            {/* <Radio value={"bank"} style={{ paddingBottom: 10 }}>
              <span
                className="app-HomeScreen-txt10"
                style={{ color: props.color }}
              >
                <BsBank className="app-HomeScreen-icon" />
                {t("bank")}
              </span>
            </Radio>
            <Radio value={"cash"} style={{ paddingBottom: 10 }}>
              <span
                className="app-HomeScreen-txt10"
                style={{ color: props.color }}
              >
                <BsCashStack className="app-HomeScreen-icon" />
                {t("cash")}
              </span>
            </Radio>
            <Radio value={"card"} style={{ paddingBottom: 10 }}>
              <span
                className="app-HomeScreen-txt10"
                style={{ color: props.color }}
              >
                {" "}
                <FaCreditCard className="app-HomeScreen-icon" />
                {t("card")}
              </span>
            </Radio>

            <Radio value={"cash_pickup"} style={{ paddingBottom: 10 }}>
              <div>
                {" "}
                <span
                  className="app-HomeScreen-txt10"
                  style={{ color: props.color }}
                >
                  {" "}
                  <FaRegMoneyBillAlt className="app-HomeScreen-icon" />
                  {t("cash_pickup")}
                </span>
              </div>
            </Radio>
            <Radio value={"payment_at_shop"} style={{ paddingBottom: 10 }}>
              <div>
                {" "}
                <span
                  className="app-HomeScreen-txt10"
                  style={{ color: props.color }}
                >
                  {" "}
                  <FaShop className="app-HomeScreen-icon" />
                  {t("payment_at_shop")}
                </span>
              </div>
            </Radio> */}
            <Radio value={"wallet"} style={{ paddingBottom: 10 }}>
              <div>
                {" "}
                <span
                  className="app-HomeScreen-txt10"
                  style={{ color: props.color }}
                >
                  {" "}
                  <IoWalletOutline className="app-HomeScreen-icon" />
                  {t("wallet")}
                </span>
              </div>
            </Radio>
          </Space>
        </Radio.Group>
        {paymentType === "wallet" ? (
          <div className="app-HomeScreen-Modal-total-box">
            {" "}
            <Card
              style={{ width: 320 }}
              className={
                balance() < 0
                  ? "app-HomerScreen-red-card dark:app-HomerScreen-red-card-dark "
                  : "app-HomerScreen-green-card dark:app-HomerScreen-green-card-dark"
              }
            >
              <Row>
                <Col className="balanceTxt">
                  {props?.admin ? t("cash_in_wallet1") : t("cash_in_wallet")}
                  &nbsp;:
                </Col>
                <Col className="balanceTxt">
                  {(
                    walletCash?.balance * props?.currentInfo?.exchange_rate
                  ).toFixed(2)}{" "}
                  {props?.currentInfo?.currency_code}
                </Col>
              </Row>
              <Row>
                <Col className="balanceTxt">{t("cost")}&nbsp;:</Col>
                <Col className="balanceTxt">
                  {props?.form?.getFieldValue("amount")}{" "}
                  {props?.currentInfo?.currency_code}
                </Col>
              </Row>
              <hr style={{ borderColor: "#FEB71B" }} />
              <Row>
                <Col className="balanceTxt">
                  {t("balance_in_wallet")}&nbsp;:
                </Col>
                <Col className="balanceTxt">
                  {(
                    walletCash?.balance * props?.currentInfo?.exchange_rate -
                    props?.form?.getFieldValue("amount")
                  ).toFixed(2)}{" "}
                  {props?.currentInfo?.currency_code}
                </Col>
              </Row>
            </Card>
            {balance() < 0 ? (
              <div style={{ width: 300, color: "red" }}>
                *&nbsp;{t("insufficient_balance_in_wallet")}
              </div>
            ) : null}
          </div>
        ) : null}
      </Modal>
    </>
  );
}
