const UserThemeObj = {
  token: {
    colorTextDisabled: "#fff",
  },
  components: {
    Select: {
      selectorBg: "transparent",
    },
    Pagination: {
      itemActiveBg: "transparent",
    },
    Slider: {
      railBg: "#6c757d29",
    },
  },
};

export default UserThemeObj;
