import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { StockOutlined } from "@ant-design/icons";
import { IoGiftOutline } from "react-icons/io5";
import { MdOutlineSip } from "react-icons/md";
import { Tag } from "antd";

function LogItems(props: any) {
  return (
    <div className="transactionLogScreen-LogItems">
      <div className="transactionLogScreen-LogItems-icon">
        {props?.item?.type === "gift" ? (
          <IoGiftOutline />
        ) : props?.item?.type === "trade" ? (
          <StockOutlined />
        ) : (
          <MdOutlineSip />
        )}
      </div>
      <div className="transactionLogScreen-LogItems2">
        <Row style={{ height: "100%" }}>
          <Col sm={3} xs={6}>
            <div className="transactionLogScreen-LogItems3">
              <div className="transactionLogScreen-LogItemsText1">
                {props?.item?.metal_type.replace(/_/g, " ")}
              </div>
              <div className="transactionLogScreen-LogItemsText2">
                {props?.item?.type.replace("_", " ")}/
                {props?.item?.sub_type.replace("_", " ")}
              </div>
            </div>
          </Col>
          <Col sm={2} xs={6}>
            <div className="transactionLogScreen-LogItems3">
              {props?.item?.payment_type.replace(/_/g, " ")}
            </div>
          </Col>
          <Col sm={2} xs={6}>
            <div className="transactionLogScreen-LogItems3">
              <div>
                <Tag className="transactionLogScreen-LogItems3-Tag1">
                  {props?.item?.amount?.toFixed(2)}&nbsp;AED
                </Tag>
              </div>
            </div>
          </Col>
          <Col sm={2} xs={6}>
            <div className="transactionLogScreen-LogItems3">
              <div style={{ textTransform: "lowercase" }}>
                <Tag className="transactionLogScreen-LogItems3-Tag2">
                  {`${
                    props?.item?.weight
                      ? props?.item?.weight?.toFixed(2)
                      : "0.00"
                  } g`}
                </Tag>
              </div>
            </div>
          </Col>
          <Col sm={2} xs={12}>
            <div className="transactionLogScreen-LogItems3">
              on {moment(props?.item?.createdAt).format("D MMM, YYYY")}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LogItems;
