
function numberToWords(amount: any) {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  function convertBelow100(num: any) {
    if (num < 10) {
      return ones[num];
    } else if (num >= 11 && num <= 19) {
      return teens[num - 10];
    } else {
      return (
        tens[Math.floor(num / 10)] +
        (num % 10 !== 0 ? " " + ones[num % 10] : "")
      );
    }
  }

  function convertBelow1000(num: any) {
    if (num < 100) {
      return convertBelow100(num);
    } else {
      return (
        ones[Math.floor(num / 100)] +
        " Hundred" +
        (num % 100 !== 0 ? " and " + convertBelow100(num % 100) : "")
      );
    }
  }

  function convert(amount: any) {
    if (amount === 0) {
      return "Zero";
    } else {
      const crores = Math.floor(amount / 10000000); // Calculating crores
      const lakhs = Math.floor((amount % 10000000) / 100000); // Calculating lakhs
      let thousands: any = Math.floor((amount % 100000) / 1000);
      let remainder: any = amount % 1000;
      remainder = parseInt(remainder);
      thousands = parseInt(thousands);
      let result = "";

      if (crores > 0) {
        result += convertBelow1000(crores) + " Crore";
        if (lakhs > 0 || thousands > 0 || remainder > 0) {
          result += " ";
        }
      }

      if (lakhs > 0) {
        result += convertBelow1000(lakhs) + " Lakhs";
        if (thousands > 0 || remainder > 0) {
          result += " ";
        }
      }

      if (thousands > 0) {
        result += convertBelow1000(thousands) + " Thousand";
        if (remainder > 0) {
          result += " ";
        }
      }

      if (remainder > 0) {
        result += convertBelow1000(remainder);
      }

      return result;
    }
  }

  function wordsSplit(amount: any) {
    console.log("", amount);
    if (Number.isInteger(amount)) {
      return convert(amount);
    } else {
      let splitt = amount.split(".");
      let words = convert([splitt[0]]);
      let words2 = convert([splitt[1]]);
      return (
        words +
        " " +
        'AED' +
        "s" +
        " and " +
        words2 +
        " " +
        "Fils"
      );
    }
  }

  return wordsSplit(amount);
}

export { numberToWords };
