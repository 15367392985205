import { Button, Modal } from "antd";
import React, { useState } from "react";
import "../styles.scss";

import { TbInfoOctagonFilled } from "react-icons/tb";
import LoginModal from "../../../components/LoginModal";

function UserNotAvailable(props: any) {
  const [login, setLogin] = useState(false);

  return (
    <div>
      <Modal
        open={props?.isOpen}
        onCancel={() => {
          props?.onClose();
        }}
        width={450}
        footer={false}
        className="userNotFound-screen"
        closable={false}
        bodyStyle={{ padding: "0px" }}
      >
        <br />
        <div className="userNotFound-screen-Box">
          <div className="userNotFound-screen-Box1">
            <TbInfoOctagonFilled color="#FC971F" size={25} />
            Please log in to access the gold trading feature
          </div>
        </div>
        <hr className="userNotFound-screen-Border" />
        <div className="userNotFound-screen-ButtonBox">
          <Button
            className="userNotFound-screen-cancel"
            onClick={() => props?.onClose()}
          >
            {" "}
            Cancel
          </Button>
          <Button
            className="userNotFound-screen-Login"
            onClick={() => {
              props?.onClose();
              setLogin(!login);
            }}
          >
            {" "}
            Login Now
          </Button>
        </div>
      </Modal>
      {login ? (
        <LoginModal open={login} close={() => setLogin(!login)} />
      ) : null}
    </div>
  );
}

export default UserNotAvailable;
