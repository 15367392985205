import { PictureOutlined } from "@ant-design/icons";
import { Card, Image, Table, Tag, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { FaRegEye } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

interface TradeItem {
  id: string;
  order_id: string;
  productDetails: {
    image: string;
  };
  userDetails: {
    name: string;
  };
  paymentType: string;
  status: string;
  color: string;
  createdAt: string;
}

interface DataTableProps {
  allTrade?: TradeItem[];
  isLoading?: boolean;
  isLoading2?: boolean;
  deleteCategory?: (id: number) => void;
}

const DataTable: React.FC<DataTableProps> = ({
  allTrade = [],
  isLoading = false,
  isLoading2 = false,
  deleteCategory,
}) => {
  const navigate = useNavigate();

  const handleDelete = (id: string) => {
    if (deleteCategory) {
      deleteCategory(Number(id));
    }
  };

  const columns: ColumnsType<TradeItem> = [
    {
      title: "Image",
      dataIndex: ["productDetails", "image"],
      key: "productDetails.image",
      width: 100,
      render: (image: string) => (
        <div className="w-16 h-16">
          {image ? (
            <Image
              src={image}
              alt="Product"
              width={64}
              height={64}
              className="object-cover rounded"
              fallback="/api/placeholder/64/64"
            />
          ) : (
            <div className="w-16 h-16 bg-gray-100 rounded flex items-center justify-center">
              <PictureOutlined className="text-2xl text-gray-400" />
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Order Id",
      dataIndex: "order_id",
      key: "order_id",
      render: (text: string) => <Text className="font-medium">#{text}</Text>,
    },
    {
      title: "Name",
      dataIndex: ["userDetails", "name"],
      key: "userDetails.name",
      render: (text: string) => <Text className="font-medium">{text}</Text>,
    },
    {
      title: "Product Name",
      dataIndex: ["productDetails", "name"],
      key: "userDetails.name",
      render: (text: string) => <Text className="font-medium">{text}</Text>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (text: string) => (
        <Text className="line-clamp-2" title={text}>
          {text}
        </Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: TradeItem) => (
        <Tag color={record.color}>{status}</Tag>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <Text>{moment(date).format("DD-MM-YYYY")}</Text>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id: string) => (
        <div className="flex items-center gap-3">
          <FaRegEye
            size={25}
            className="cursor-pointer text-blue-500 hover:text-blue-600"
            onClick={() => navigate(`/admin/orders/${id}`)}
          />
        </div>
      ),
    },
  ];

  return (
    <Card className="common-table-card">
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={allTrade}
        pagination={false}
        className="common-table-custom-scrollbar"
        size="middle"
        rowKey="id"
        scroll={{ x: "max-content" }}
      />
    </Card>
  );
};

export default DataTable;
