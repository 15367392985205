import "../styles.scss";

import { Button, message, Modal, notification } from "antd";

import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import { BsBank2 } from "react-icons/bs";
import { FaBuilding, FaCreditCard, FaTruckPickup } from "react-icons/fa6";
import { HiMiniBanknotes, HiOutlineArrowLongRight } from "react-icons/hi2";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";

import { Fade } from "react-awesome-reveal";

import API from "../../../../config/api";
import { POST } from "../../../../utlis/apiCalls";

function PaymentModal(props: any) {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state?.auth?.user);
  const country = useSelector((state: any) => state?.currencyInfo?.current);

  const [isloading, setLoading] = useState(false);

  const [showMethod, setShowMethod] = useState(false);
  const [amountToBeEnter, setAmountToBeEnter] = useState(true);

  const [methodType, setMethodType] = useState(
    props.process !== "withdrawal" ? "card" : "cash"
  );
  const [error, setError] = useState("");
  const [amount, setAmount] = useState<any>();
  const [othersResponse, setOthersResponse] = useState<any>();

  const paymets = [
    {
      method: "Pay with card",
      icon: <FaCreditCard size={25} />,
      desc: "Pay using cards ",
      type: "card",
    },
    {
      method:
        props?.process === "deposited" ? "Pay with cash" : "Withdraw via cash",
      icon: <HiMiniBanknotes size={25} />,
      desc: "Pay using liquid money",
      type: "cash",
    },
    {
      method:
        props?.process === "deposited" ? "Pay via bank" : "Withdraw via bank",
      icon: <BsBank2 size={25} />,
      desc: "Pay using bank ",
      type: "bank",
    },
    {
      method:
        props?.process === "deposited"
          ? "Pay Via Showroom"
          : "Withdraw via showroom",
      icon: <FaBuilding size={25} />,
      desc: "Pay using our Showroom ",
      type: "payment_at_shop",
    },
    {
      method: "Cash pickup",
      icon: <FaTruckPickup size={25} />,
      desc: "Pay using pickup system ",
      type: "cash_pickup",
    },
  ];

  const onChangeAmount = (e: any) => {
    const val = e.target.value;
    setAmount(val);
    if (val > 0) {
      setError("");
    }
  };

  const handlePaymentClick = () => {
    if (amount <= 0) {
      setError("Enter a valid amount");
    } else {
      setError("");
      setShowMethod(true);
      setAmountToBeEnter(false);
    }
  };

  const handlePaymentOptionClick = () => {
    if (methodType === "card" && props.process !== "withdrawal") {
      navigate("/auth/wallet/checkoutScreen", { state: { amount: amount } });
    } else if (methodType === "bank") {
      navigate("/auth/BankDetails");
    } else {
      onOthers(methodType);
    }
  };

  const onOthers = async (item: any) => {
    setLoading(true);
    try {
      const obj = {
        amount: Number(amount),
        paymentType: item,
        userId: user?.id,
        type: String(props.process), //withdrawal or deposited
      };
      let response: any = await POST(API.WALLET_REQUEST, obj);
      if (response?.status) {
        setOthersResponse(response);
        setLoading(false);
        message.success("successfully initiated");
        props?.onCancel();
        setMethodType(props.process !== "withdrawal" ? "card" : "cash");
        setShowMethod(false);
        setAmountToBeEnter(true);
        setAmount("");
      }
    } catch (err) {
      console.error("Error in onOthers:", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        visible={props?.open}
        className="app-WalletScreen-Section1-payment"
        onCancel={() => {
          props?.onCancel();
          setMethodType(props.process !== "withdrawal" ? "card" : "cash");
          setShowMethod(false);
          setAmount("");
          setAmountToBeEnter(true);
        }}
        bodyStyle={{ padding: "0px" }}
        footer={false}
        closable={false}
        width={800}
      >
        <Container fluid={false}>
          <div className="app-WalletScreen-Section1-payment-box1">Payment</div>
          <hr style={{ border: "1px solid #feb71b" }} />
          <br />
          {amountToBeEnter ? (
            <Fade>
              <br />
              <div className="app-WalletScreen-Section1-payment-box7">
                <div>Enter amount</div>
                <br />
                <input
                  type="number"
                  className="app-WalletScreen-Section1-payment-amountInput"
                  placeholder="00.00 AED"
                  onChange={(e) => onChangeAmount(e)}
                  value={amount}
                />
                {error && <div style={{ color: "red" }}>{error}</div>}
                <br />
                <div className="app-WalletScreen-Section1-payment-box-8">
                  <div
                    className="app-WalletScreen-Section1-payment-box-9"
                    onClick={() => setAmount("300")}
                  >
                    +300
                  </div>
                  <div
                    className="app-WalletScreen-Section1-payment-box-9"
                    onClick={() => setAmount("1000")}
                  >
                    +1000
                  </div>
                  <div
                    className="app-WalletScreen-Section1-payment-box-9"
                    onClick={() => setAmount("2000")}
                  >
                    +2000
                  </div>
                </div>
                <br />
              </div>
            </Fade>
          ) : null}
          {showMethod ? (
            <Fade>
              <div>Choose payment methods</div>
              <br />
              <Row className="g-3">
                {paymets
                  ?.slice(
                    props.process === "withdrawal" ? 1 : 0,
                    props.process === "withdrawal" ? 4 : paymets.length
                  )
                  .map((item: any) => {
                    return (
                      <Col md={6}>
                        <div
                          className={`app-WalletScreen-Section1-payment-box2 ${
                            methodType === item?.type ? "active" : ""
                          }`}
                          onClick={() => setMethodType(item?.type)}
                        >
                          <div className="app-WalletScreen-Section1-payment-box4">
                            {methodType === item?.type ? (
                              <IoIosRadioButtonOn color="#c99000" size={25} />
                            ) : (
                              <IoIosRadioButtonOff color="grey" size={30} />
                            )}
                          </div>
                          <div className="app-WalletScreen-Section1-payment-box5">
                            <div>
                              {item?.method}
                              <br />
                              <span className="app-WalletScreen-Section1-payment-txt2">
                                {item?.desc}
                              </span>
                            </div>
                          </div>
                          <div className="app-WalletScreen-Section1-payment-box6">
                            {item?.icon}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </Fade>
          ) : null}
          <br />
          <div className="app-WalletScreen-Section1-payment-box3">
            <Button
              className="app-WalletScreen-Section1-payment-Button"
              onClick={() =>
                amountToBeEnter
                  ? handlePaymentClick()
                  : handlePaymentOptionClick()
              }
              loading={isloading}
            >
              {props?.process == "deposited"
                ? `Proceed to pay`
                : "Proceed to withdraw"}
              &nbsp;
              <HiOutlineArrowLongRight />
            </Button>
          </div>
        </Container>
      </Modal>
    </div>
  );
}

export default PaymentModal;
