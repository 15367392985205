import { Col, Row } from "react-bootstrap";
import { Skeleton } from "antd";

export default function CustomerCard(props?: any) {
  return (
    <div>
      {props?.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Row>
            <Col>
              <div className="admin-CustomerScreen-txt8">Name </div>
              <div className="admin-CustomerScreen-txt5">
                {props?.customerDetails?.name || "N/A"}
              </div>
            </Col>
            <Col>
              <div className="admin-CustomerScreen-txt8">Phone Number </div>
              <div className="admin-CustomerScreen-txt5">
                {props?.customerDetails?.phoneNumber}
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div className="admin-CustomerScreen-txt8">Email </div>
              <div className="admin-CustomerScreen-txt5">
                {props?.customerDetails?.email || "N/A"}
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
