import moment from "moment";
import API from "../../../../config/api";
import { numberToWords } from "../../../../shared/helpers/numberToWords";

export const template1:any = (props:any) => {
  try {
    return `<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i" rel="stylesheet" />
    <title>Tax GO Invoice</title>
</head>

<body style="width: 100%;align-content: center;margin: auto;">
    <div style="margin: 20px;">
        <table style="margin-top: 60px !important;margin: auto;width: 100%;">
            <tbody>
            <tr style="text-align: center;
            font-size: 15px;
            font-weight: 700;
            text-align:'center';
            text-transform: uppercase;
            text-decoration: underline;">
            <td colspan="6"><b style="margin-bottom:25px">INVOICE</b></td>
        </tr>
                <tr>
                    <td><b>
                           LSG
                        </b></td>
                    <td colspan="2"><b>
                        </b></td>
                    <td style="text-align: center" rowspan="6"><img style="width: 60px; height: 80px;"
                            src="${API.FILE_PATH}" />
                    </td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td><b>
                    </b></td>
                    <td><b>Mobile Number</b></td>
                    <td><b>E-mail</b></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                    ${"United Arab Emirates"}
            <br>
                    </td>
                    <td>

                        ${"+971 54 581 6161"}
                    </td>
                    <td>
                        ${"lsg@gmail.com"}
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <hr>
      <table style="margin-top: 25px !important;margin: auto;width: 100%;">
        <tbody> 
          <tr>
            <td><b>Customer Name</b></td>
            <td><b>Customer Vat No</b></td>
            <td><b>Invoice Date</b></td>
            <td><b></b></td>
            <td><b>Invoice No</b></td>
            <td><b>Reference</b></td>
          </tr>
          <tr>
            <td>${props?.invoiceDetails?.customer?.name || props?.invoiceDetails?.cname}</td>
            <td>${props?.invoiceDetails?.customer?.vat_number || ""}</td>
            <td>${moment(props?.invoiceDetails?.sdate).format("DD/MM/YYYY")}</td>
            <td>
            ${
              props?.invoiceDetails?.deladdress == "" || ''
                ? ""
                : `
            Delivery Address: ${props?.invoiceDetails?.deladdress}`
            }</td>
            <td>${props?.invoiceDetails?.invoiceno}</td>
            <td>${props?.invoiceDetails?.reference || ""}</td>
          </tr>
        </tbody>
        </table>
        <table style="margin-top: 25px !important;margin: auto;width: 100%;">
            <tbody style="text-align: center;">
                <tr style="background: gray;color: white;text-align: center;font-size: 12px;">
                    <td style="height: 40px;" width="12%"><b>
                    Item Code
                        </b></td>
                    <td width="12%"><b>
                            Description
                        </b></td>
                    <td width="12%"><b>Qty/Hrs</b></td>
                    <td width="12"><b> Unit</b></td>
                    <td width="12%"><b>Price/Rate</b></td>
                    <td width="12%"><b>Total</b></td>
                </tr>
                ${props?.invoiceItems
                  .map(
                    (product: any) => `
                <tr style="font-size: 12px;">
                    <td>
                        ${product?.product?.icode || ""}
                    </td>
                    <td>
                        ${product?.product?.idescription || ""}
                    </td>
                    <td>
                        ${product?.quantity || ""}
                    </td>
                    <td>
                    ${product?.product?.unit || ""}
                    </td>
                    <td>
                        ${product?.product?.costprice || ""}
                    </td>
                    <td>
                       AED ${product?.total}
                    </td>
                </tr>
            `
                  )
                  .join("")}

            </tbody>
        </table>
        <hr>
        <table style="margin-top: 25px !important;margin: auto;width: 100%;">
            <tbody style="text-align: center;">
                <tr style="font-size: 12px;">
                    <td width="30%"></td>
                    <td width="30%"></td>
                </tr>
      
                <tr style="background: gray;color: white;text-align: center;font-size: 12px;">
                    <td style="height: 35px;" width="5%"><b>Total</b></td>
                    <td width="5%" style="color: black;"><b>
                            ${props?.invoiceDetails?.total} AED
                        </b>
                    </td>
                </tr>

                <tr style="text-align:end;">
                  <td style="width: 70%";></td>
                    <td><b>
                    ${numberToWords(props?.invoiceDetails?.total)} AEDs </b></td>
                </tr>
            </tbody>
        </table>
        <table style="border-spacing: 0px;margin-top: 10px !important;margin: auto;width: 100%;">
            <tbody style="text-align: center;">
                <tr style="border: none;background: lightgray;color: black;text-align: start;font-size: 12px;">
                    <td rowspan="2" width="52%" style="border: none;height: 60px;" colspan="2" width="12%">
                        <b style="margin-left:10px; padding-top:10px;">Invoice Descriptions</b>
                        <p style="text-align:start; margin-left:10px;">
                            ${props?.invoiceDetails?.quotes == null ? "" : props?.invoiceDetails?.quotes || ""}
                            .</br>
                        </p>
                        <br>
                        <br>
                        <b style="margin-left:10px;">Terms and Conditions</b>
                        <p style="text-align:start; margin-left:10px;">
                        ${props?.invoiceDetails?.terms == null ? "" : props?.invoiceDetails?.terms || ""}
                            </br>
                        </p>
                    </td> 
                    <td style="text-align: right;font-size: 16px; line-height: 25px; padding: 20px; width: 35%">             <br>
             <b>Grand Total ${props?.invoiceDetails?.total || ""}</b>
             <div>
             </div>
         </td>
                </tr>
                    </tbody>
        </table>
                    <table style="width:100%">
      <tr style="width:100%;height:20px">
        <td style="width:50%">
      </td>
        <td style="width:50%"></td>
      </tr>
      <tr style="width:100%;height:70px">
        <td style="width:50%"></td>
        <td
          style="width:50%;border-top:1px solid gray;border-right:1px solid gray;border-left:1px solid gray">
        </td>
      </tr>
      <tr style="width:100%;height:10px">
      <td style="text-align: left; line-height: 25px; padding: 20px; width: 50%">
     
    </td>
        <td
          style="width:50%;border-bottom:1px solid gray;border-right:1px solid gray;border-left:1px solid gray;padding-left:120px">
          Authorised Signatory</td>
      </tr>
    </table>
            
        ${
          props?.banking
            ? `<div>
            <p style="text-align: center;"><b>Payment Information</b></p>
        </div>
        <table style="margin-top: 25px !important;margin: auto;width: 100%;border: 1px solid black;">
            <tbody style="text-align: center;">
                <tr style="background: gray;color: black;text-align: center;font-size: 12px;">
                    <td style="height: 25px;" width="12%"><b>Bank Holder Name</b></td>
                    <td width="12%"><b>Account No</b></td>
                    <td width="12%"><b>Paid Method</b></td>
                    <td width="12%"><b>Amount</b></td>
                    <td width="12%"><b>Date</b></td>
                    <td width="12%"><b>Payment Type</b></td>
                </tr>

               
                ${props?.banking
                  .map(
                    (bankInf: any) => `
      <tr style="font-size: 12px;">
          <td>
              ${"LSG BANK"}
          </td>
          <td>
              ${bankInf?.bankInf?.laccount || ""}
          </td>
          <td>
              ${bankInf.paidmethod || ""}
          </td>
          <td>
              ${bankInf.amount}
          </td>
          <td>
              ${moment(bankInf.date).format("DD/MM/YYYY")}
          </td>
          <td>
              ${bankInf.type}
          </td>
      </tr>
  `
                  )
                  .join("")}


            </tbody>
        </table>

        <table style="width:100%">
            <tr style="width:100%;height:30px">
                <td style="width:50%"></td>
                <td style="width:50%"></td>
            </tr>
            <tr style="width:100%;height:70px">
                <td style="width:50%"></td>
                <td style="width:50%;border-top:1px solid gray;border-right:1px solid gray;border-left:1px solid gray">
                </td>
            </tr>
            <tr style="width:100%;height:10px">
                <td style="width:50%"></td>
                <td
                    style="width:50%;border-bottom:1px solid gray;border-right:1px solid gray;border-left:1px solid gray;padding-left:120px">
                    Authorised Signatory</td>
            </tr>
        </table>`
            : ""
        }
        <div style="font-size: 10px; margin-top: 25px;">
            <p style="text-align: center;">Created by <a target="_blank"
                    href="https://www.taxgoglobal.com/">Taxgoglobal Limited</a></p>
        </div>
    </div>
</body>
 </html>`;
  } catch (error) {
    console.log("err", error);
  }
};

