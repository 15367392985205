import { IconType } from "react-icons";
import { BsCardHeading } from "react-icons/bs";
import { CgUnavailable } from "react-icons/cg";
import { FiUser } from "react-icons/fi";
import { IoGiftOutline, IoWalletOutline } from "react-icons/io5";
import { BiNotification } from "react-icons/bi";
import { GoCreditCard } from "react-icons/go";
import { RiLogoutBoxRLine, RiHome3Line } from "react-icons/ri";
import { MdDashboard, MdOutlineRedeem, MdOutlineSip } from "react-icons/md";
import { PiNotificationDuotone } from "react-icons/pi";
import { IoMdHome } from "react-icons/io";
function DynamicIcon(props: any) {
  console.log(props);
  type IconName =
    | "RiHome3Line"
    | "IoWalletOutline"
    | "MdOutlineRedeem"
    | "CgUnavailable"
    | "IoGiftOutline"
    | "MdOutlineSip"
    | "FiUser"
    | "BiNotification"
    | "RiLogoutBoxRLine"
    | "GoCreditCard"
    | "BsCardHeading"
    | "PiNotificationDuotone";

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }
  function Icon({ iconName, size = 20, color = "gray" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      RiHome3Line: RiHome3Line,
      MdOutlineRedeem: MdOutlineRedeem,
      CgUnavailable: CgUnavailable,
      MdOutlineSip: MdOutlineSip,
      IoGiftOutline: IoGiftOutline,
      BsCardHeading: BsCardHeading,
      FiUser: FiUser,
      BiNotification: BiNotification,
      RiLogoutBoxRLine: RiLogoutBoxRLine,
      GoCreditCard: GoCreditCard,
      PiNotificationDuotone: PiNotificationDuotone,
      IoWalletOutline: IoWalletOutline,
    };

    if (!icons.hasOwnProperty(iconName)) {
      console.warn(
        `Icon '${iconName}' not found. Rendering default icon instead.`
      );
      iconName = "CgUnavailable"; // set default icon name
    }

    const IconComponent = icons[iconName];

    return <IconComponent size={size} color={color} />;
  }

  return <Icon iconName={props.icon} size={18} color={props.color} />;
}

export default DynamicIcon;
