import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import "./styles.scss";

const CheckoutForm = () => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [message, setMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }: any) => {
        console.log("paymentIntent", paymentIntent);
      });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      console.log("Stripe or elements not loaded");
      return;
    }
    setIsLoading(true);

    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "https://digigold.luckystargold.com/paymentSuccess",
          // return_url: "http://localhost:3000/paymentSuccess",
        },
      });

      console.log("result", result);

      if (
        result?.error.type === "card_error" ||
        result?.error.type === "validation_error"
      ) {
        setMessage(result?.error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error in payment confirmation:", error);
    }
  };
  return (
    <div className="paymentCardSection">
      <div className="paymentCard-container ">
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">{isLoading ? <Spin /> : "Pay now"}</span>
          </button>
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;
