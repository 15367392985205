import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import img1 from "../../../assets/images/certificate.svg";
import img2 from "../../../assets/images/mobile-wallet.svg";
import img3 from "../../../assets/images/savings.svg";
import img4 from "../../../assets/images/vault.svg";
import img5 from "../../../assets/images/ingot.svg";
import { useTranslation } from "react-i18next";

function Section2() {
  const { t } = useTranslation();

  return (
    <div className="homeScreen-section2">
      <Container>
        <div className="homeScreen-text10 center">
          {t("whygo")} <span className="homeScreen-text2">{t("digital")}</span>{" "}
          ?
        </div>
        <div className="homeScreen-text11 center">
          {t("Every_feature_on_LSG")}
        </div>
        <br />
        <br />
        <Row>
          <Col sm={4} xs={12}>
            <Fade delay={100} style={{ height: "100%" }}>
              <div className="homeScreen-box7">
                <div>
                  <img src={img1} />
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: 10 }}>
                    {t("Guaranteed_24K_Gold")}
                  </div>
                  <div className="homeScreen-text11">
                    {t("Unlike_local_vendors")}
                  </div>
                </div>
              </div>
            </Fade>
            <br />
          </Col>
          <Col sm={4} xs={12}>
            <Fade delay={200} style={{ height: "100%" }}>
              <div className="homeScreen-box7">
                <div>
                  <img src={img2} />
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: 10 }}>
                    {t("Sell_any_time_from_home")}{" "}
                  </div>
                  <div className="homeScreen-text11">{t("Sell_anytime")}</div>
                </div>
              </div>
            </Fade>
            <br />
          </Col>
          <Col sm={4} xs={12}>
            <Fade delay={300} style={{ height: "100%" }}>
              <div className="homeScreen-box7">
                <div>
                  <img src={img3} />
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: 10 }}>
                    {t("Earn_income_on_gold")}
                  </div>
                  <div className="homeScreen-text11">{t("You_can_lend")}</div>
                </div>
              </div>
            </Fade>
            <br />
          </Col>
          <Col sm={4} xs={12}>
            <Fade delay={400} style={{ height: "100%" }}>
              <div className="homeScreen-box7">
                <div>
                  <img src={img3} />
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: 10 }}>
                    {t("Buy_as_low_as_₹10")}
                  </div>
                  <div className="homeScreen-text11">{t("Digital_does")}</div>
                </div>
              </div>
            </Fade>
            <br />
          </Col>
          <Col sm={4} xs={12}>
            <Fade delay={500} style={{ height: "100%" }}>
              <div className="homeScreen-box7">
                <div>
                  <img src={img4} />
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: 10 }}>
                    {t("Safety_guaranteed")}
                  </div>
                  <div className="homeScreen-text11">
                    {t("Unlike_physical_gold")}
                  </div>
                </div>
              </div>
            </Fade>
            <br />
          </Col>
          <Col sm={4} xs={12}>
            <Fade delay={600} style={{ height: "100%" }}>
              <div className="homeScreen-box7">
                <div>
                  <img src={img5} />
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: 10 }}>
                    {t("Convert_to_physical_gold")}{" "}
                  </div>
                  <div className="homeScreen-text11">
                    {t("You_can_convert")}
                  </div>
                </div>
              </div>
            </Fade>
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Section2;
