import React from "react";
import { List } from "antd";

import ListItems from "./listItems";
import { useTranslation } from "react-i18next";

function AppTradeList(props: any) {
  const { t } = useTranslation();
  let titles = [
    {
      head: t("Metel_type"),
    },
    {
      head: t("bs"),
    },
    {
      head: `${t("weight")}(g)`,
    },
  ];
  return (
    <div className="app-HomeScreen-Section4-AppTradeList">
      <List
        itemLayout="horizontal"
        grid={{ gutter: 2, column: 3 }}
        dataSource={titles}
        bordered={false}
        renderItem={(item: any) => (
          <List.Item>
            <div className="app-HomeScreen-Section4-AppTradeList-Txt1">
              {item.head}
            </div>
          </List.Item>
        )}
      />
      <List
        itemLayout="horizontal"
        dataSource={props?.data}
        bordered={false}
        renderItem={(item: any) => <ListItems data={item} />}
      />
    </div>
  );
}

export default AppTradeList;
