import { PictureOutlined } from "@ant-design/icons";
import { Card, Image, Popconfirm, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { AiOutlineEdit, AiTwotoneDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

interface TradeItem {
  id: string;
  name: string;
  description: string;
  images: string;
  createdAt: string;
}

interface DataTableProps {
  allTrade?: TradeItem[];
  isLoading?: boolean;
  isLoading2?: boolean;
  deleteCategory?: (id: number) => void;
}

const DataTable: React.FC<DataTableProps> = ({
  allTrade = [],
  isLoading = false,
  isLoading2 = false,
  deleteCategory,
}) => {
  const navigate = useNavigate();

  const handleDelete = (id: string) => {
    if (deleteCategory) {
      deleteCategory(Number(id));
    }
  };

  const columns: ColumnsType<TradeItem> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 100,
      render: (image: string) => (
        <div className="w-16 h-16">
          {image && image.length > 0 ? (
            <Image
              src={image}
              alt="Product"
              width={64}
              height={64}
              className="object-cover rounded"
              fallback="/api/placeholder/64/64"
            />
          ) : (
            <div className="w-16 h-16 bg-gray-100 rounded flex items-center justify-center">
              <PictureOutlined className="text-2xl text-gray-400" />
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <Text className="font-medium">{text}</Text>,
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      width: 700,
      render: (text: string) => (
        <Text className="line-clamp-2" title={text}>
          {text}
        </Text>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (date: string) => (
        <Text>{moment(date).format("DD-MM-YYYY")}</Text>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id: string, record) => (
        <div className="flex items-center gap-3">
          <AiOutlineEdit
            size={25}
            className="cursor-pointer text-blue-500 hover:text-blue-600"
            onClick={() => navigate(`/admin/category/${id}`)}
          />
          <Popconfirm
            title="Delete Category"
            description={`Are you sure you want to delete "${record.name}"?`}
            onConfirm={() => handleDelete(id)}
            okText="Yes, Delete"
            cancelText="No, Cancel"
            okButtonProps={{
              danger: true,
              size: "middle",
              loading: isLoading2,
            }}
            cancelButtonProps={{
              size: "middle",
            }}
            placement="leftTop"
          >
            <span className="inline-block">
              <AiTwotoneDelete
                size={25}
                className="cursor-pointer text-red-500 hover:text-red-600"
              />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Card className="common-table-card">
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={allTrade}
        pagination={false}
        className="common-table-custom-scrollbar"
        size="middle"
        rowKey="id"
        scroll={{ x: "max-content" }}
      />
    </Card>
  );
};

export default DataTable;
