import React from "react";
import { Button, Modal } from "antd";

import { useTranslation } from "react-i18next";

function ConfirmModal(props: any) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={props?.modalOpen}
        onCancel={() => props?.close()}
        className="app-RedeemScreen-Confirmation"
        footer={false}
        width={400}
      >
        <div style={{ height: "35px" }}></div>
        <div className="app-RedeemScreen-confirmBox">
          <div className="app-RedeemScreen-txt5">{t("are_you_sure")}</div>
          <div className="app-redeemScreen-txt4">
            {props?.form.getFieldValue("type") === "weight"
              ? t("confirm_delivery_redeeming_wallet_weight")
              : t("confirm_delivery_redeeming_sold_gold_value")}
          </div>
          <br />
          <div className="app-RedeemScreen-confirmBox2">
            <Button
              className="app-RedeemScreen-confirmButton cancel"
              onClick={() => props?.close()}
            >
              cancel
            </Button>
            <Button
              className="app-RedeemScreen-confirmButton"
              onClick={() => props?.handleRedeem()}
              loading={props?.isLoading}
            >
              submit
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ConfirmModal;
