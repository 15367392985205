import {
  Button,
  Card,
  Input,
  Pagination,
  Table,
  Tag,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import API from "../../../config/api";
import { GET } from "../../../utlis/apiCalls";
import PageHeader from "../../routes/pageHeader";
import moment from "moment";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { StatusColor } from "../../../shared/helpers/functions";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import useDebounce from "../../../shared/helpers/useDebounce";

export default function RedeemRequest(props: any) {
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [redeems, setRedeems] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    getRedeems(page, pageSize);
  }, [page, useDebounce(searchValue, 1500)]);

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "user",
      key: "name",
      render: (text: any) => <span>{text?.name}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any) => (
        <div style={{ textTransform: "capitalize" }}>
          {text === "amount" ? "Amount" : text === "weight" ? "Weight" : ""}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => (
        <span style={{ textTransform: "capitalize" }}>
          <Tag color={StatusColor(text)}>{text}</Tag>
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "View More",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <MdOutlineRemoveRedEye
          size={25}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/admin/redeemdetails/${record.id}`)}
        />
      ),
    },
  ];
  const getRedeems = async (p: any, t: any) => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.REDEEM_LIST +
          `?order=DESC&page=${p}&take=${t}&search=${searchValue}`,
        null
      );
      if (response?.data?.meta?.itemCount) {
        setRedeems(response?.data?.data);
        setMeta(response?.data?.meta);
      } else {
        setRedeems([]);
        setMeta([]);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (page: number, pageSize: any) => {
    setPage(page);
    setPageSize(pageSize);
    getRedeems(page, pageSize);
  };
  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };
  const handlePageSize = (current: number, page: number) => {
    setPage(current);
    setPageSize(page);
  };
  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Redeem Request"}>
          <div className="d-flex">
            <Input
              style={{ width: "200px" }}
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              prefix={<IoSearchOutline size={20} color="gray" />}
              placeholder="Search here.."
              suffix={
                <IoIosClose
                  size={20}
                  color="grey"
                  cursor={"pointer"}
                  onClick={() => setSearchValue("")}
                />
              }
            />
            <div style={{ width: 20 }} />
            <Button
              type="primary"
              onClick={() => {
                getRedeems(1, 10);
              }}
            >
              Refresh
            </Button>
          </div>
        </PageHeader>
        <Card>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={redeems}
            pagination={false}
            size="small"
          />
          <Pagination
            current={page}
            pageSize={pageSize}
            total={meta?.itemCount || 0}
            defaultCurrent={1}
            responsive={true}
            defaultPageSize={pageSize || 10}
            disabled={false}
            onShowSizeChange={handlePageSize}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
        </Card>
      </Container>
    </div>
  );
}
