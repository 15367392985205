import { useState } from "react";
import "./styles.scss";

import { Col, Container, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import EditModal from "./components/editModal";
import EditProfilePhoto from "./components/editProfilePhoto";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import UserForm from "./components/userForm";

function ProfileScreen() {
  const { t } = useTranslation();

  const User = useSelector((state: any) => state.auth.user);
  const [isLoading, setisLoading] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentField, setCurrentField] = useState<string | null>(null);
  const [modal3, setModal3] = useState(false);

  const handleeditcancel = () => {
    setModal3(false);
  };
  return (
    <div>
      <Container>
        <Row className="g-3">
          <Col md={12} xs={12}>
            <Section1 data={User} EditProfile={() => setModal3(true)} />
          </Col>
          <Col md={4} xs={12}>
            <UserForm
              data={User}
              onEdit={() => setIsModalOpen(true)}
              onSetFeild={(val: any) => setCurrentField(val)}
            />
          </Col>
          <Col md={8} xs={12}>
            <Section2 />
          </Col>
        </Row>
      </Container>
      <EditProfilePhoto open={modal3} close={handleeditcancel} />
      <EditModal
        isModalOpen={isModalOpen}
        isLoading={isLoading}
        setisLoading={setisLoading}
        onClose={() => setIsModalOpen(false)}
        type={currentField}
      />
    </div>
  );
}

export default ProfileScreen;
