import "./styles.scss";
import { Button, Card, Pagination, notification } from "antd";
import { Container } from "react-bootstrap";
import PageHeader from "../../routes/pageHeader";
import API from "../../../config/api";
import { GET } from "../../../utlis/apiCalls";
import { useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/es/table";

export default function Enquiries() {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [enquires, setEnquires] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
  ];
  useEffect(() => {
    getEnquiries(page, pageSize);
  }, []);
  const getEnquiries = async (p: any, t: any) => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.CONTACT_ALL + `?page=${p}&take=${t}`,
        null
      );
      if (response?.meta?.itemCount) {
        setEnquires(response?.data);
        setMeta(response?.meta);
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (page: number, pageSize: any) => {
    setPage(page);
    setPageSize(pageSize);
    getEnquiries(page, pageSize);
  };
  const handlePageSize = (current: number, page: number) => {
    setPage(current);
    setPageSize(page);
  };
  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Enquiries"}>
          <div>
            <Button
              type="primary"
              onClick={() => {
                getEnquiries(1, 10);
              }}
            >
              Refresh
            </Button>
          </div>
        </PageHeader>
        <Card>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={enquires}
            pagination={false}
            size="small"
          />
          <Pagination
            current={page}
            pageSize={pageSize}
            total={meta?.itemCount || 0}
            defaultCurrent={1}
            responsive={true}
            defaultPageSize={pageSize || 10}
            disabled={false}
            onShowSizeChange={handlePageSize}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
        </Card>
      </Container>
    </div>
  );
}
