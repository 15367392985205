import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { TbInfoOctagonFilled } from "react-icons/tb";

import "./styles.scss";

function KycStatusInfo(props: any) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        className="KycStatusInfo-screen"
        open={props?.isOpen}
        onCancel={() => {
          props?.onClose();
        }}
        footer={false}
        closable={false}
      >
        <br />
        <div className="KycStatusInfo-Box">
          <div className="KycStatusInfo-Box-1">
            <TbInfoOctagonFilled color="#FC971F" size={25} />
            {props.data === "not_submitted" ? (
              <div>
                Your KYC verification is not completed.
                <br />
                please complete the KYC process to access full features
              </div>
            ) : props.data === "pending" ? (
              <div>
                {" "}
                KYC Verification is Under Process we will notify you <br />
                once it completed
              </div>
            ) : (
              "Your Kyc is Rejected  Please Resubmit with correct Details"
            )}
          </div>
        </div>
        <hr className="KycStatusInfo-screen-Border" />
        <div className="KycStatusInfo-screen-ButtonBox">
          <Button
            className="KycStatusInfo-screen-cancel"
            onClick={() => props?.onClose()}
          >
            {" "}
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default KycStatusInfo;
