import {
  Button,
  Card,
  Form,
  Modal,
  Timeline,
  message,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LuRefreshCcw } from "react-icons/lu";
import { useLocation, useParams } from "react-router-dom";
import "../styles.scss";

import API from "../../../../config/api";
import { GET, POST } from "../../../../utlis/apiCalls";
import PageHeader from "../../../routes/pageHeader";

export default function WalletDetails() {
  const param = useParams();
  const Id = param.id;
  const [form] = Form.useForm();
  const location = useLocation();
  const locData = location?.state?.item;
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isLoading2, setIsLoading2] = useState<any>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState<string>();
  const [data, setData] = useState<any>();

  useEffect(() => {
    getRequest();
  }, [refresh]);

  const getRequest = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.WALLET_FETCH_ONE + Id);
      if (response?.status) {
        setData(response.data);
      } else {
        setData([]);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (val: any) => {
    setIsLoading2(true);
    let url = API.WALLET_REQUEST_ACCEPT + Id;
    try {
      let obj = {
        status: status,
        comment: val?.comment,
      };
      const response: any = await POST(url, obj);
      if (response?.status) {
        setIsLoading2(false);
        message.success(response?.message);
        setIsModalOpen(false);
        getRequest();
      } else {
        message.error(response?.message);
        setIsLoading2(false);
      }
    } catch (error) {
      setIsLoading2(false);
      message.error("Oops somthing went wrong");

      console.log(error);
    }
  };

  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Wallet Request Details"}>
          <div className="d-flex">
            <div style={{ width: 20 }} />
            <div>
              <Button
                type="primary"
                color="green"
                disabled={data?.status == "accepted" ? true : false}
                onClick={() => {
                  setStatus("accepted");
                  setIsModalOpen(true);
                }}
              >
                Accept
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                type="primary"
                disabled={data?.status == "accepted" ? true : false}
                danger
                onClick={() => {
                  setStatus("rejected");
                  setIsModalOpen(true);
                }}
              >
                Reject
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                <LuRefreshCcw size={20} />
              </Button>
            </div>
          </div>
        </PageHeader>
        <div className="overFlow-Box1">
          <Card loading={isLoading}>
            <Row>
              <Col sm={6}>
                {" "}
                <div style={{ marginLeft: 50 }}>
                  <div className="mb-3">
                    <span className="WalletDetails-text1">Name :</span>
                    <span className="tradeDetails-text2">
                      {locData?.user?.name}
                    </span>
                  </div>

                  <div className="mb-3">
                    <span className="WalletDetails-text1">Amount :</span>{" "}
                    <span className="WalletDetails-text2">{data?.amount}</span>
                  </div>

                  <div className="mb-3">
                    <span className="WalletDetails-text1">Type :</span>{" "}
                    <span className="WalletDetails-text2">{data?.type}</span>
                  </div>
                  <div className="mb-3">
                    <span className="WalletDetails-text1">Payment Type :</span>{" "}
                    <span className="WalletDetails-text2">
                      {data?.paymentType}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="WalletDetails-text1">Status :</span>{" "}
                    <span className="WalletDetails-text2">{data?.status}</span>
                  </div>
                  <div className="mb-3">
                    <span className="WalletDetails-text1">Create Date :</span>{" "}
                    <span className="WalletDetails-text2">
                      {moment(data?.createdAt)
                        .utcOffset(moment(data?.createdAt).utcOffset())
                        .format("DD/MMM/YYYY h:mm A")}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="WalletDetails-text1">Comment :</span>{" "}
                    <span className="WalletDetails-text2">{data?.comment}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
      <Modal
        onOk={form.submit}
        title={`Are You Sure to ${
          status == "accepted" ? "Accept" : "Reject"
        } Request`}
        open={isModalOpen}
        okButtonProps={{ loading: isLoading2 }}
        onCancel={() => setIsModalOpen(false)}
      >
        <div style={{ height: 20 }} />
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label="Comment"
            name="comment"
            rules={[{ required: true, message: "Please input your Comment!" }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
