import React, { useEffect, useState } from 'react';
import { Modal, Button, message } from 'antd';
import moment from "moment";
import API from "../../../../config/api";
import { template1 } from './templates';
import { useSelector } from 'react-redux';
import axios from 'axios';

const InvoiceGenerator = (props:any) => {
  const user = useSelector((state: any) => state?.auth?.user);
  const [activeTemplate, setActiveTemplate] = useState<any>();
  

    
  useEffect(() => {
    getInvoiceDetails(props?.tradeDetailsId);
}, [props?.tradeDetailsId]);


  const getInvoiceDetails = async (id:any) => {
      try {
        const url = `https://taxgov2-server.taxgoglobal.com/taxgov2/public_api/getSalesInvoice/${id}/sales`
        const res = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.taxgoToken}`,
          },
        });
        if (res.status) {
          let template=await template1(res.data.data);
          setActiveTemplate(template)
        }
      } catch (err) {
      //   message.error('Failed to fetch invoice details');
      }
  };
  

  const handleDownload = async () => {
    try {
      await downLoadPdf(activeTemplate);
    } catch (err) {
      message.error("Failed to download the PDF");
    }
  };

  const downLoadPdf = async (templates:any) => {
    let templateContent = templates?.replace("\r\n", "");
    templateContent = templateContent?.replace('\\"', '"');
    let updatedTemplateContent = templateContent
      ?.replace(/[\r\n]/g, "")
      ?.replace(/\\"/g, "")
      ?.replace(/[\u0100-\uffff]/g, "");
  
    const encodedString = btoa(unescape(encodeURIComponent(updatedTemplateContent)));
    const pdf_url = API.PDF_GENERATE_URL;
    const pdfData = {
      filename: "",
      html: encodedString,
      isDownload: true,
      sendEmail: false,
      type: "",
      userid: "",
    };
  
    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.taxgoToken}`,
      },
      body: JSON.stringify(pdfData),
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  
    const pdfBlob = await response.arrayBuffer();
    const blob = new Blob([pdfBlob], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `CustomerReceipt_${moment(new Date()).format("DD-MM-YYYY")}`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Modal
        title="Invoice"
        open={props?.visible}
        width={900}
        onCancel={props?.close}
        footer={[
          <Button key="download" type="primary" onClick={()=> handleDownload()}>
            Download Invoice
          </Button>,
          <Button key="close" onClick={() => props?.close}>
            Close
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: activeTemplate }} />
      </Modal>
    </>
  );
};

export default InvoiceGenerator;
