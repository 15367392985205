import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Container, Row } from "react-bootstrap";
import { BsEmojiSmile } from "react-icons/bs";
import { GoRows } from "react-icons/go";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoCallOutline } from "react-icons/io5";

function ContactSection2() {
  const [answerShow, setAnswerShow] = useState<any>({});
  const Questions = [
    {
      icon: <BsEmojiSmile color="#fff" size={20} />,
      Header: "Is that possible to by Gold by 10 AED?",
      desc: "Yeah sure we provide an digital platform to by Gold start with 10 AED",
    },
    {
      icon: <GoRows color="#fff" size={20} />,
      Header: "How do I fill my KYC details?",
      desc: "Go to Home and click on your profile there is an option for create KYC",
    },
    {
      icon: <IoCallOutline color="#fff" size={20} />,
      Header: "How do i change my mobile number?",
      desc: "Go to home and click on your profile then you can see view profile  and you can change mobile number",
    },
    {
      icon: <BsEmojiSmile color="#fff" size={20} />,
      Header: "How SIP works",
      desc: "You can set a target for the amount of gold you want to acquire within a specific duration. There are options for monthly, daily, and yearly plans.",
    },
    {
      icon: <BsEmojiSmile color="#fff" size={20} />,
      Header: "How can I retrieve gold from this platform",
      desc: "You can terminate the job and gold amount will add you Wallet",
    },
  ];

  const handleclickTrue = (index: any) => {
    setAnswerShow((prevState: any) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const handleclickFalse = (index: any) => {
    setAnswerShow((prevState: any) => ({
      ...prevState,
      [index]: false,
    }));
  };
  return (
    <div className="contactScreen-section2">
      <Container>
        <Fade>
          <br />
          <br />
          <div className="homeScreen-text10 center">
            Frequenly asked
            <span className="homeScreen-text2"> &nbsp;Questions </span>
          </div>
          <div className="homeScreen-text11 center">
            Let us know how we can help
          </div>
        </Fade>
        <br />
        <br />
        <br />
        <Row>
          <Col sm={3}></Col>
          <Col sm={6}>
            {Questions?.map((items: any, index: any) => {
              return (
                <Fade>
                  <div
                    className="contactScreen-section2-Box1 "
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="contactScreen-Box2 center">
                      {items?.icon}
                    </div>
                    <div className="contactScreen-section2-Box3">
                      <div>
                        {items?.Header}
                        <br />
                        {answerShow[`${index}`] ? (
                          <div className="homeScreen-text11">{items?.desc}</div>
                        ) : null}
                      </div>
                      {answerShow[`${index}`] ? (
                        <div>
                          <IoIosArrowUp
                            color="#fff"
                            size={30}
                            onClick={() => handleclickFalse(index)}
                          />
                        </div>
                      ) : (
                        <div>
                          <IoIosArrowDown
                            color="#fff"
                            size={30}
                            onClick={() => handleclickTrue(index)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Fade>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactSection2;
