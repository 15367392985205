import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../routes/pageHeader";
import { Button, Card, Tag, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GET } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import Cards from "./components/cards";
import "./styles.scss";
import CustomerCard from "./components/customerCard";
import CustomerTradeTable from "./components/customerTradeTable";
import { LuRefreshCcw } from "react-icons/lu";
import UpdateWalletModal from "./components/updateWalletModal";
import { StatusColor } from "../../../shared/helpers/functions";
import { statusType } from "../../../shared/types/kyc";
import OpenModal from "./components/openModal";
import { useSelector } from "react-redux";

export default function CustomerDetails(props: any) {
  const param: any = useParams();
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [wallet, setWallet] = useState<any>([]);
  const [isLoading, setisLoading] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [walletModalOpen, setWalletModalOpen] = useState<boolean>(false);
  const [customerDetails, setCustomerDetails] = useState<any>();
  const [openModel, setOpenModel] = useState<any>(false);
  const liveRate = useSelector((state: any) => state.liveRate);

  // console.log("param?.id", param?.id);

  useEffect(() => {
    LoadWallet();
    getCustomerDetails();
  }, [refresh]);
  const getCustomerDetails = async () => {
    try {
      setisLoading(true);
      const response: any = await GET(API.CUSTOMER_DETAILS + param?.id, null);
      if (response?.status) {
        setCustomerDetails(response?.data);
      } else {
        notificationApi.error(response?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  };
  const LoadWallet = async () => {
    try {
      const response: any = await GET(API.GET_WALLET + param?.id, null);
      if (response?.status) {
        setWallet(response?.data);
      } else {
      }
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
    }
  };

  // Function to find the balance for a specific metal type in the wallet
  const findBalance = useCallback(
    (metalType: string) => {
      const metalItem: any = wallet?.find(
        (item: any) => item.metal_type === metalType,
      );
      return metalItem?.balance || 0;
    },
    [wallet],
  );

  const gold_995 = findBalance('gold_995');
  const gold_9999 = findBalance('gold_9999');
  const silver_999 = findBalance('silver_999');
  const cash = findBalance('cash');

  // Function to get the live buy rate for each metal type
  const getLiveRateValue = useCallback(
    (metalType: any) => {
      const liveRates: any = {
        'gold_995': liveRate?.current?.find((item: any) => item.metal === 'GOLD 995')?.liveSellRate,
        'gold_9999': liveRate?.current?.find((item: any) => item.metal === 'GOLD 9999')?.liveSellRate,
        'silver_999': liveRate?.current?.find((item: any) => item.metal === 'SILVER 999')?.liveSellRate,
      };
      return liveRates[metalType] || 0;
    },
    [liveRate]
  );

  // Calculating total value of each metal based on the current live rates
  const gold_995_value = gold_995 * getLiveRateValue('gold_995');
  const gold_9999_value = gold_9999 * getLiveRateValue('gold_9999');
  const silver_999_value = silver_999 * getLiveRateValue('silver_999');
  const totalAssets = cash + gold_995_value + gold_9999_value + silver_999_value;
  

  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Customer Details"}>
          <div className="d-flex">
            <div>
              <Button
                type="primary"
                disabled={
                  [
                    statusType.accept,
                    statusType.reject,
                    statusType.pending,
                  ].includes(customerDetails?.kyc_status)
                    ? false
                    : true
                }
                onClick={() => setOpenModel(true)}
              >
                BUY / SELL
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                type="primary"
                disabled={
                  [
                    statusType.accept,
                    statusType.reject,
                    statusType.pending,
                  ].includes(customerDetails?.kyc_status)
                    ? false
                    : true
                }
                onClick={() => navigate(`/admin/kycdetails/${btoa(param?.id)}`)}
              >
                View KYC
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                type="primary"
                color="green"
                onClick={() => setWalletModalOpen(true)}
              >
                Update Wallet
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                <LuRefreshCcw size={20} />
              </Button>
            </div>
          </div>
        </PageHeader>
        <div className="overFlow-Box1">
          <Card>
            <Row>
              <Col md={8}>
                <div className="d-flex justify-content-between align-items-center">
                  {" "}
                  <div className="admin-CustomerScreen-txt2">Customer Info</div>
                  <div>
                    <Tag
                      style={{ fontSize: 14 }}
                      color={StatusColor(customerDetails?.kyc_status)}
                    >
                      {customerDetails?.kyc_status === "accept" ||
                      customerDetails?.kyc_status === "reject"
                        ? `KYC is ${customerDetails?.kyc_status}`
                        : customerDetails?.kyc_status === "not_submitted"
                        ? `KYC is not submitted`
                        : ""}
                    </Tag>
                  </div>
                </div>

                <CustomerCard
                  customerId={param?.id}
                  customerDetails={customerDetails}
                  isLoading={isLoading}
                />
                <hr className="primary-color" />
                <div className="admin-CustomerScreen-txt2">
                  Customer Trade History
                </div>
                <CustomerTradeTable customerId={param?.id} refresh={refresh} />
              </Col>
              <Col md={4}>
                <div className="admin-CustomerScreen-box2">
                  <div className="admin-CustomerScreen-txt2">
                    Customer Wallet
                  </div>
                  {isLoading ? null : (
                    <>
                      {wallet?.map((item: any) => {
                        return (
                          <Cards
                          getLiveRateValue={getLiveRateValue}
                            key={item?.id}
                            type={item?.metal_type}
                            balance={item?.balance}
                          />
                        );
                      })}
                      <div className="admin-CustomerScreen-card">
                        <div className="admin-CustomerScreen-row">
                          <div className="admin-CustomerScreen-txt8">
                            Total Assets
                          </div>
                        </div>
                        <div style={{ margin: 4 }} />
                        <div
                          className="admin-CustomerScreen-row"
                          style={{ alignItems: "center" }}
                        >
                          <div>
                            <div className="admin-CustomerScreen-txt6">
                              Available Balance
                            </div>
                          </div>
                          <div style={{ flex: 1 }} />
                          <div className="admin-CustomerScreen-txt5">
                            {totalAssets?.toFixed(2)}{" "} AED
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
      <UpdateWalletModal
        userId={param?.id}
        isOpen={walletModalOpen}
        close={() => setWalletModalOpen(false)}
        refreshWallet={() => LoadWallet()}
      />
      {openModel ? (
        <OpenModal
          userId={param?.id}
          isOpen={openModel}
          close={() => setOpenModel(false)}
        />
      ) : null}
    </div>
  );
}
