import React, { useEffect, useRef, useState } from "react";
import { Typography, Button } from "antd";
import "./styles.scss";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { POST } from "../../../../utlis/apiCalls";
import API from "../../../../config/api";

const PaymentSuccess = () => {
  const navigation = useNavigate();

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      const urlParams = new URLSearchParams(window.location.search);
      const paymentIntent = urlParams.get("payment_intent");
      retrievePaymentIntent(paymentIntent);
      isMounted.current = true;
    }
  }, []);

  const retrievePaymentIntent = (paymentId: any) => {
    console.log("paymentId", paymentId);
    try {
      let reqBOdy = {
        paymentId,
      };
      const res = POST(API.PAYMENT_RETRIEVE, reqBOdy);
      console.log("res", res);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <div className="payment-success-container">
      <div className="payment-circle">
        <FaCheck color="#ffffff" size={45} />
      </div>
      <Typography.Title level={2} className="success-text">
        Payment Successful!
      </Typography.Title>
      <Button type="primary" onClick={() => navigation("/auth/home")}>
        Back to Home
      </Button>
    </div>
  );
};

export default PaymentSuccess;
