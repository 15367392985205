import { Col, Row } from "react-bootstrap";
import { useHandleTokenExpiration } from "../CommonHeader/helpers/fetchingDatas";
import { useNavigate } from "react-router-dom";

import {
  IoIosNotificationsOutline,
  IoIosHelpCircleOutline,
  IoIosLogOut,
} from "react-icons/io";
import { SlUser } from "react-icons/sl";
import { useEffect, useState } from "react";
import { GET } from "../../utlis/apiCalls";
import API from "../../config/api";
import Helpa from "../../app/screens/helpSession";

const ProfilePopover = (props: any) => {
  const handleTokenExpiration = useHandleTokenExpiration();
  const navigate = useNavigate();

  const [data, setData] = useState<any>();
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  useEffect(() => {
    fetchWallet();
  }, []);

  const fetchWallet = async () => {
    try {
      const response: any = await GET(API.GET_WALLET + props?.data?.id, null);
      if (response.status) {
        setData(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="LoginModal-Popover">
      <div className="LoginModal-Popover-img">
        <div>
          {props?.data?.image ? <img src={props?.data?.image} /> : null}
        </div>
        <div>
          <div>{props?.data?.name ? props?.data?.name : "N/A"}</div>
          <div className="LoginModal-Popover-text1">
            {props?.data?.email ? props?.data?.email : "N/A"}
          </div>
        </div>
      </div>
      <Row style={{ padding: 10, paddingTop: 0 }}>
        {data?.map((item: any) => (
          <Col sm={6} xs={6} style={{ margin: 0, padding: 0 }}>
            <div
              className="LoginModal-Popover-Box1"
              onClick={() => navigate("/auth/wallet")}
            >
              <div className="LoginModal-Popover-text2">
                {item?.metal_type === "cash"
                  ? "Wallet"
                  : item?.metal_type.replace(/_/g, " ")}
              </div>
              <div className="LoginModal-Popover-text3">
                {" "}
                {item?.metal_type === "cash"
                  ? `AED  ${item?.balance?.toFixed(2)}`
                  : `${item?.balance?.toFixed(2)} gm`}
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <div
        className="LoginModal-Popover-item"
        onClick={() => navigate("/auth/notification")}
      >
        <div>
          <IoIosNotificationsOutline size={22} />
        </div>
        <div>Notifications</div>
      </div>
      <div
        className="LoginModal-Popover-item"
        onClick={() => setHelpModalOpen(true)}
      >
        <div>
          <IoIosHelpCircleOutline size={22} />
        </div>
        <div>Helps</div>
      </div>
      <div
        className="LoginModal-Popover-item"
        onClick={() => navigate("/auth/profile")}
      >
        <div>
          <SlUser />
        </div>
        <div>My Profile</div>
      </div>
      <div className="LoginModal-Popover-item">
        <a href="/">Terms & Conditions</a>
      </div>
      <div className="LoginModal-Popover-item">
        <a href="/">Privacy Policy</a>
      </div>
      <div
        className="LoginModal-Popover-item"
        style={{ color: "red" }}
        onClick={handleTokenExpiration}
      >
        <div>
          <IoIosLogOut size={22} />
        </div>
        <div>Logout</div>
      </div>
      {helpModalOpen ? (
        <Helpa
          open={helpModalOpen}
          onClose={() => setHelpModalOpen(!helpModalOpen)}
        />
      ) : null}
    </div>
  );
};

export default ProfilePopover;
