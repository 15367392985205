import "./styles.scss";
import { Container } from "react-bootstrap";
import PageHeader from "../../routes/pageHeader";
import DataTable from "./components/dataTable";
import { Button, DatePicker, Input, Pagination, notification } from "antd";
import { useEffect, useState } from "react";
import API from "../../../config/api";
import { GET } from "../../../utlis/apiCalls";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import useDebounce from "../../../shared/helpers/useDebounce";

export default function Trading() {
  const [allTrade, setAllTrade] = useState<any>();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [date, setDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const { RangePicker } = DatePicker;
  useEffect(() => {
    getAllTrade();
  }, [page, useDebounce(searchValue, 1500), date, pageSize]);
  const getAllTrade = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.TRADING_LIST +
          `?order=DESC&page=${page}&take=${pageSize}&search=${searchValue}&fromDate=${date.fromDate}&toDate=${date.toDate}`,
        null
      );
      if (response?.status) {
        setAllTrade(response.data.data);
        setMeta(response?.data?.meta);
      } else {
        setAllTrade([]);
        setMeta([]);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const handlePageSize = (current: number, page: number) => {
    setPage(current);
    setPageSize(page);
  };
  const DateChange = (dates: any, dateStrings: any) => {
    setDate({
      fromDate: dateStrings[0],
      toDate: dateStrings[1],
    });
  };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };
  const handleRefresh = () => {
    setSearchValue("");
    setPage(1);
    getAllTrade();
  };
  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Trading"}>
          <div className="d-flex">
            <RangePicker onChange={DateChange} disabledDate={disabledDate} />
            <div style={{ width: 20 }} />
            <Input
              style={{ width: "200px" }}
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              prefix={<IoSearchOutline size={20} color="gray" />}
              placeholder="Search here.."
              suffix={
                <IoIosClose
                  size={20}
                  color="grey"
                  cursor={"pointer"}
                  onClick={() => setSearchValue("")}
                />
              }
            />
            <div style={{ width: 20 }} />
            <div>
              <Button type="primary" onClick={handleRefresh}>
                Refresh
              </Button>
            </div>
          </div>
        </PageHeader>
        <div className="overFlow-Box1">
          <DataTable isLoading={isLoading} allTrade={allTrade} />
          <Pagination
            current={page || 1}
            pageSize={pageSize || 10}
            total={meta?.itemCount || 0}
            defaultCurrent={1}
            responsive={true}
            onShowSizeChange={handlePageSize}
            defaultPageSize={pageSize || 10}
            disabled={false}
            hideOnSinglePage={pageSize < 10 ? true : false}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
        </div>
      </Container>
    </div>
  );
}
