import "./App.scss";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { message, ConfigProvider } from "antd";
import { BroadcastChannel } from "broadcast-channel";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import ProtectedRoute from "./utlis/protectedRoute";

import HomeScreen from "./website/homeScreen";
import AboutScreen from "./website/aboutScreen";
import ContactScreen from "./website/contactScreen";
import PrivacyPolicy from "./website/privacyScreen";
import TermCondition from "./website/term-condition";
import AppRoutes from "./app/routes";
import AdminRoutes from "./admin/routes";
import io from "socket.io-client";
import { storeCurrencyInfo } from "./Redux/slice/currencyInfoSlice";
import { store } from "./Redux/slice/liveRateSlice";

import API from "./config/api";

import { GET } from "./utlis/apiCalls";
import { clearReduxData } from "./utlis/redux.util";
import ThemeObj from "./theme";
import PaymentSuccess from "./app/screens/walletScreen.tsx/paymentRedirect/success";
import DeleteUser from "./website/DeleteUser";

function App() {
  const User = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();
  const Direction = useSelector((state: any) => state.Language.direction);
  const isMounted = useRef(false);
  const logoutChannel = new BroadcastChannel("logout");

  const navigate = useNavigate();

  useEffect(() => {
    const handleLogoutMessage = () => {
      message.warning({
        type: "loading",
        content: "Logout Successfully..",
        duration: 2,
      });
      clearReduxData(dispatch);
      navigate("/");
    };
    logoutChannel.onmessage = handleLogoutMessage;
    return () => {
      logoutChannel.onmessage = null;
    };
  }, [dispatch, navigate]);

  // useEffect(() => {
  //   setInterval(() => {
  //     getLiveRate();
  //   }, 3000);
  // }, []);

  useEffect(() => {
    const now = new Date();
    const weekendDays = [0, 6];
    if (weekendDays.includes(now.getDay())) {
      fetchFallbackData();
    }
    getCurrencyInfo();
  }, []);

  const fetchFallbackData = async () => {
    try {
      const response: any = await GET(API.GET_CLOSING_RATE);
      if (response?.status) {
        dispatch(store(response?.data?.closingRate));
      } else {
        console.error("Failed to fetch fallback live rates");
      }
    } catch (error) {
      console.error("Error fetching fallback live rates", error);
    }
  };

  useEffect(() => {
    const socket = io(API.GET_LIVERATE, {
      transports: ["websocket"],
    });
    socket.on("data", (newData) => {
      if (newData?.length) {
        dispatch(store(newData));
      }
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  const getCurrencyInfo = async () => {
    try {
      const response: any = await GET(API.CURRENCY_USER_LIST, null);
      if (response?.status) {
        dispatch(storeCurrencyInfo(response?.data));
      }
    } catch (error) {}
  };

  return (
    <I18nextProvider i18n={i18n}>
      <div dir={Direction}>
        <ConfigProvider theme={ThemeObj}>
          <Routes>
            <Route path="/" index element={<HomeScreen />} />
            <Route path="/about" index element={<AboutScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/term-condition" element={<TermCondition />} />
            <Route path="/paymentSuccess" element={<PaymentSuccess />} />
            <Route path="/delete/:id" element={<DeleteUser />} />
          </Routes>
        </ConfigProvider>
        <Routes>
          <Route
            path="/auth/*"
            element={
              <ProtectedRoute isSignedIn={User.auth}>
                <AppRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute
                isSignedIn={User?.user?.role === 2 ? true : false}
              >
                <AdminRoutes />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </I18nextProvider>
  );
}

export default App;
