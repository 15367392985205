import { Form, InputNumber, Modal, message, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { metalType } from "../../../../shared/types/metalTypes";
import { changeMetalTypeFormat } from "../../../../shared/helpers/functions";
import { useState } from "react";
import { PUT } from "../../../../utlis/apiCalls";
import API from "../../../../config/api";
export default function UpdateWalletModal(props: any) {
  const [isLoading, setIsLoading] = useState<any>(false);
  const [form] = useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const updateWallet = async (val: any) => {
    try {
      setIsLoading(true);
      let obj: any = [];
      Object.keys(val).map((item: any) => {
        obj.push({
          metal_type: item,
          amount: val[item],
        });
      });
      const response: any = await PUT(API.UPDATE_WALLET + props?.userId, obj);
      if (response?.status) {
        notificationApi.success({
          message: "successfully Updated Wallet",
        });
        props?.refreshWallet();
        form.resetFields();
        props?.close();
      } else {
        notificationApi.error({ message: response?.message });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      {contextHolder}
      <Modal
        open={props?.isOpen}
        onOk={form.submit}
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ loading: isLoading }}
        okText="Submit"
        onCancel={props?.close}
      >
        <div className="admin-CustomerScreen-txt2">Update Wallet</div>
        <Form onFinish={updateWallet} form={form} layout="vertical">
          {Object.values(metalType).map((item: any) => (
            <Form.Item
              label={
                <div className="admin-CustomerScreen-txt8">
                  Add {changeMetalTypeFormat(item)}
                </div>
              }
              name={item}
              initialValue={0}
              rules={[
                {
                  type: "number",
                  message: "The amount should be a numeric value.",
                },
                {
                  required: true,
                  message: "Please Enter the Desired Amount",
                },
              ]}
            >
              <InputNumber
                disabled={isLoading}
                addonAfter={item === "cash" ? "AED" : "gram"}
                min={0}
                type="number"
              />
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </div>
  );
}
