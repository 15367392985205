import { LoadingOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Form, Input, Tooltip, notification } from "antd";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import { useSelector } from "react-redux";

import useDebounce from "../../../shared/helpers/useDebounce";
import { currentLoctionType } from "../../../shared/types/CommonType";

import API from "../../../config/api";
import { AUTO_COMPLETE, CURRENT_LOCATION } from "../../../utlis/apiCalls";

function AutoCompleteLocation(props: any) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationApi, contextHolder2] = notification.useNotification();

  const [options, setOptions] = useState<any[]>([]);
  const [searchParam, setSearchParam] = useState(
    props?.location ? props?.location : ""
  );
  const searchLocation = useDebounce(searchParam, 300);

  useEffect(() => {
    form.setFieldsValue({ location: props?.location });
  }, [form]);

  useEffect(() => {
    locationAutoComplete(searchLocation);
  }, [searchLocation]);

  const getAutoComplete = (value: string) => {
    setSearchParam(value);
  };

  const getLocation = (option: { value: string; key: string }) => {
    try {
      const locationObj: any = {};
      locationObj.Place_id = option.key;
      const url =
        API.GET_LOCATION + `?place_id=${option.key}&key=${API.GGL_TOKEN}`;
      fetch(url, {
        method: "get",
        headers: {
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          return response.json();
        })
        .catch((err) => {
          notificationApi.error({ message: t("failed_to_get_location") });
          return;
        })
        .then(async (response) => {
          if (response?.status == "OK") {
            getCurrentLocation(
              response.results[0]?.geometry?.location?.lat,
              response.results[0]?.geometry?.location?.lng
            );
          } else {
            notificationApi.error({
              message: `Request failed ,reason:${response.status}`,
            });
          }
        })
        .catch((err) => {
          notificationApi.error({ message: t("failed_to_get_location") });
        });
    } catch (err) {}
  };

  const getSuggetions = (result: any) => {
    const array: any[] = [];
    result?.predictions?.forEach((item: any) => {
      array.push({
        value: item?.structured_formatting?.main_text,
        key: item?.place_id,
      });
    });
    setOptions(array);
  };

  const locationAutoComplete = async (value: string) => {
    try {
      if (value?.length > 2) {
        setIsLoading(true);
        const response: any = await AUTO_COMPLETE(value);
        if (response?.status == "OK") {
          getSuggetions(response);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      notificationApi.error({ message: t("failed_to_get_location_details") });
    }
  };

  function handleLocationClick() {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleLocation, handleError);
    } else {
      setIsLoading(false);
      notificationApi.error({ message: `GeoLocation not supported` });
    }
  }
  const handleLocation = (position: any) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    getCurrentLocation(latitude, longitude);
  };

  const handleError = (error: any) => {
    setIsLoading(false);
    notificationApi.error({
      message: `Unable to get your Location. reason:${error.message}`,
    });
  };

  const getCurrentLocation = async (lat: number, long: number) => {
    try {
      const locationInfo: any = await CURRENT_LOCATION(lat, long);
      if (locationInfo.status) {
        const locationData: currentLoctionType = locationInfo.data;
        let obj = {
          pincode: locationData?.postal_code,
          state: locationData?.state,
          street: locationData?.subLocality,
          city: locationData?.taluk,
          address: locationData.street_address ?? locationData.premise,
          location: locationData?.subLocality,
          country: locationData?.country,
          lat: lat,
          long: long,
        };
        props?.setCurrentLocation(obj);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notificationApi.error({ message: t("failed_to_get_location_details") });
      }
    } catch (err) {
      setIsLoading(false);
      notificationApi.error({ message: t("failed_to_get_location_details") });
    }
  };

  return (
    <div>
      {contextHolder2}
      <Form.Item noStyle label={props?.label ? "Location" : ""} name="location">
        <AutoComplete
          options={options}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onChange={getAutoComplete}
          onSelect={(value, option) => getLocation(option)}
          size="large"
        >
          <Input
            style={{ background: "#4140405c", border: "none" }}
            placeholder={t("search_location")}
            // size={props?.size}
            allowClear
            value={props?.location}
            prefix={<IoSearch color="#ffffffee" />}
            // suffix={
            //   <Tooltip title="Set Your current Location">
            //     {isLoading ? (
            //       <LoadingOutlined
            //         style={{ fontSize: 18, color: API.COLOR }}
            //         spin
            //       />
            //     ) : (
            //       <Button
            //         className="app-RedeemScreen-Buttons"
            //         onClick={() => handleLocationClick()}
            //       >
            //         {t("my_location")}
            //       </Button>
            //     )}
            //   </Tooltip>
            // }
          />
        </AutoComplete>
      </Form.Item>
    </div>
  );
}

export default AutoCompleteLocation;
