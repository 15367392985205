import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import Slices from "../slice";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "lsGold",
  storage: storage,
  whitelist: ["auth","liveRate","currencyInfo", "Language","theme" ],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, Slices);

const middleWareConfigs = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware(middleWareConfigs),
});
const PersistedStore = persistStore(store);
export { store, PersistedStore };
