import { createSlice } from "@reduxjs/toolkit";

const liveRateSlice = createSlice({
  name: "liveRate",
  initialState: {
    current: [],
    prev: [],
  },
  reducers: {
    store: (state, action) => {
      state.prev = state.current;
      state.current = action.payload;
    },
  },
});
export const { store } = liveRateSlice.actions;

export const selectLiveRate = (state: any) => state.liveRate;
export default liveRateSlice.reducer;
