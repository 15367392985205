import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../../../../config/api";
import { POST } from "../../../../utlis/apiCalls";
import CheckoutForm from "./checkoutScreen";
import { useSelector } from "react-redux";

const stripePromise = loadStripe(
  "pk_test_51JpFCRSHV8lwiYQsQVJI5CG02c4OBsM5cD3VzBNhj2cEbm3NRbXG6uqlW8JtjVObYE8Nq0FQZysceQXvYVAucTRL00Wv7kGDF5"
);

const PaymentScreen = () => {
  let location = useLocation();
  let amount = location?.state?.amount;

  const user = useSelector((state: any) => state?.auth?.user);
  const country = useSelector((state: any) => state?.currencyInfo?.current);

  const [loading, setLoading] = useState(true);

  const [clientSecret, setClientSecret] = useState("");

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      addPayment();
      isMounted.current = true;
    }
  }, []);

  const addPayment = async () => {
    try {
      let obj = {
        amount: String(amount),
        currency: "inr",
        user_id: user?.id,
      };
      const res: any = await POST(API.PAYMENT_CREATE, obj);
      setClientSecret(res?.client_secret);
    } catch (err) {
      console.log("err", err);
    }
  };
  const options: any = {
    clientSecret,
    appearance: {
      theme: "stripe",
      rules: {
        ".Label": {
          color: "#FFFFFF",
        },
      },
    },
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
};

export default PaymentScreen;
