import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { useSelector } from "react-redux";
import Forms from "./Forms";
import { FaStar } from "react-icons/fa";
import { MdOutlinePercent } from "react-icons/md";
import { BsFillLockFill } from "react-icons/bs";
import { IoMdArrowUp, IoMdArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";
import Currency from "./currency";
import { useState } from "react";
import { handleLiveRateByType } from "../../../shared/helpers/functions";

function Section1() {
  const { t } = useTranslation();
  const curreny = useSelector((state: any) => state?.currencyInfo?.current);
  const liveRate = useSelector((state: any) => state.liveRate);
  const User = useSelector((state: any) => state?.auth?.user);

  const [type, SetType] = useState<string>("buy");

  const compareoldPrice: any = (item: any) => {
    try {
      let amount = handleLiveRateByType(type, item);
      let previous = liveRate?.prev?.filter(
        (it: any) => it?.metal === item?.metal
      )[0];
      var a: any = amount * curreny?.exchange_rate;
      var b: number =
        handleLiveRateByType(type, previous) * curreny?.exchange_rate;
      var diffrents = a - b;
      return Number(diffrents?.toFixed(10));
    } catch (err) {
      console.log("err", err);
    }
  };

  const textColor = (item: any) => {
    if (compareoldPrice(item) > 0) {
      return "#44cc3f";
    } else if (compareoldPrice(item) < 0) {
      return "#ff1f31";
    }
  };

  return (
    <div className="homeScreen-section1">
      <br /> <br />
      <Container>
        <Row>
          <Col sm={7} xs={12}>
            <Fade delay={100}>
              <div className="homeScreen-text7">
                {t("Buy_Sell_gold_Awesome_Features")}
              </div>
            </Fade>
            <br />
            <Fade delay={200}>
              <div className="homeScreen-text1">
                {t("Start_Your_Savings")}
                &nbsp;<span className="homeScreen-text2">AED 10</span>
              </div>
            </Fade>
            <br />
            <Fade delay={300}>
              <div className="homeScreen-text3">
                {t("We_at_Lsg_DiGi")}
                <br /> {t("digital_savings")}
              </div>
            </Fade>
            <br /> <br />
            <Fade delay={400}>
              <div className="homeScreen-box5">
                <div className="homeScreen-box6">
                  <FaStar color="#00a56e" />
                </div>
                <div style={{ flex: 1 }}>{t("Best_Platform")} </div>
              </div>
            </Fade>
            <Fade delay={500}>
              <div className="homeScreen-box5">
                <div className="homeScreen-box6">
                  <MdOutlinePercent color="#00a56e" />
                </div>
                <div style={{ flex: 1 }}>{t("No_hidden_charges")}</div>
              </div>
            </Fade>
            <Fade delay={600}>
              <div className="homeScreen-box5">
                <div className="homeScreen-box6">
                  <BsFillLockFill color="#00a56e" />
                </div>
                <div style={{ flex: 1 }}>{t("Encryption")}</div>
              </div>
            </Fade>
            <br />
          </Col>
          <Col sm={5} xs={12}>
            <div className="homeScreen-box3">
              <div className="homeScreen-box2">
                <Currency />
              </div>
            </div>
            <br />
            <Row>
              {liveRate?.current?.map((item: any, index: number) => {
                return (
                  <Col sm={4} xs={4} key={index}>
                    <Fade delay={100}>
                      <div className="homeScreen-box1">
                        <div className="homeScreen-text4">{item?.metal}</div>
                        <div className="homeScreen-row">
                          <div
                            className="homeScreen-text5"
                            style={{ color: textColor(item) }}
                          >
                            {Number(
                              handleLiveRateByType(type, item) *
                                curreny?.exchange_rate
                            ).toFixed(2)}
                          </div>
                          <div>
                            {compareoldPrice(item) >= 0 ? (
                              <IoMdArrowUp />
                            ) : (
                              <IoMdArrowDown />
                            )}
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </Col>
                );
              })}
            </Row>
            <br />
            <Fade delay={400} direction="right">
              <div className="homeScreen-formbox">
                <div className="homeScreen-text8">{t("trade_now")}</div>
                <Forms
                  type={type}
                  SetType={(value: any) => SetType(value)}
                  curreny={curreny}
                  liveRate={liveRate}
                  User={User}
                />
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Section1;
