import "../styles.scss";

import { Button, Tag } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import moment from "moment";

import { useTranslation } from "react-i18next";
import { AiOutlineGift } from "react-icons/ai";
import { GiTrade } from "react-icons/gi";
import { IoWalletOutline } from "react-icons/io5";
import { MdOutlineSip, MdOutlineVerifiedUser, MdRedeem } from "react-icons/md";

import API from "../../../../config/api";
import { changeMetalTypeFormat } from "../../../../shared/helpers/functions";
import { PUT } from "../../../../utlis/apiCalls";

function LogItems(props: any) {
  const { t } = useTranslation();
  const [isLoading2, setIsLoading2] = useState(false);

  const IconType = (text: string) => {
    if (text === "trade") {
      return <GiTrade />;
    }
    if (text === "wallet") {
      return <IoWalletOutline />;
    }
    if (text === "gift") {
      return <AiOutlineGift />;
    }
    if (text === "kyc") {
      return <MdOutlineVerifiedUser />;
    }
    if (text === "sip") {
      return <MdOutlineSip />;
    }
    if (text === "redeem") {
      return <MdRedeem />;
    }
  };

  const handleRead = async (id: number) => {
    try {
      setIsLoading2(false);
      const response: any = await PUT(API.NOTIFICATION_UPATE + id, {});
      if (response?.status) {
        props?.refresh();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading2(false);
    }
  };
  return (
    <>
      <div className="NotificationScreen-LogItems">
        <div className="NotificationScreen-LogItems-icon">
          {IconType(props?.item?.type)}
        </div>
        <div className="NotificationScreen-LogItems2">
          <Row style={{ height: "100%" }}>
            <Col sm={8} xs={6}>
              <div className="app-NotificationScreen-Txt3">
                {props?.item?.type === "trade" || props?.item?.type === "kyc"
                  ? `${props?.item?.type} ${changeMetalTypeFormat(
                      props?.item?.status
                    )}`
                  : changeMetalTypeFormat(props?.item?.status)}
                <br />
                <span className="app-NotificationScreen-Txt2">
                  {changeMetalTypeFormat(props?.item?.comment)}
                </span>
              </div>
            </Col>
            <Col sm={2} xs={6} style={{ height: "100%" }}>
              <div>
                {!props?.item?.read ? (
                  <Tag className="app-NotificationScreen-newTag">New</Tag>
                ) : (
                  <>{moment(props?.item?.createdAt).format("D MMM, YYYY")}</>
                )}
              </div>
            </Col>
            <Col sm={2} xs={6} style={{ height: "100%" }}>
              <div>
                {!props?.item?.read ? (
                  <Button
                    className="app-NotificationScreen-ReadButton"
                    onClick={() => handleRead(props?.item?.id)}
                    loading={isLoading2}
                  >
                    {t("mark_as_read")}
                  </Button>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default LogItems;
