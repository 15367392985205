import { Button, Drawer } from "antd";
import Logo from "../../assets/images/logo-2.png";
import { IoClose } from "react-icons/io5";
import LanguageSelector from "./languageSelector";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function MobileDrawer(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Drawer
      placement={"right"}
      closable={false}
      onClose={() => props?.close()}
      open={props.open}
      key={"headerdrwer"}
      width={300}
      style={{
        background: "linear-gradient(to right,#000, #000)",
      }}
      title={
        <div className="WebsiteHeader-drawerHeader">
          <div>
            <img src={Logo} />
          </div>
          <div onClick={() => props?.close()}>
            <IoClose size={30} />
          </div>
        </div>
      }
    >
      <div className="WebsiteHeader-drawer">
        <Button
          size="large"
          block
          style={{ height: 50, border: 0, backgroundColor: "#00a56e" }}
          onClick={() => {
            props?.login();
            props?.close();
          }}
        >
          {t("login")}
        </Button>
        <br />
        <br />
        <div>
          <LanguageSelector />
        </div>
        <br />
        <div
          className="WebsiteHeader-drawerText1"
          onClick={() => navigate("/")}
        >
          {t("home")}
        </div>
        <div
          className="WebsiteHeader-drawerText1"
          onClick={() => navigate("/about")}
        >
          {t("About")}
        </div>
        <div
          className="WebsiteHeader-drawerText1"
          onClick={() => navigate("/contact")}
        >
          {t("contact_us")}
        </div>
        <div
          className="WebsiteHeader-drawerText1"
          onClick={() => navigate("/term-condition")}
        >
          {t("terms_and_condition")}
        </div>
        <div
          className="WebsiteHeader-drawerText1"
          onClick={() => navigate("/privacy")}
        >
          {t("privacy_policy")}
        </div>
      </div>
    </Drawer>
  );
}
