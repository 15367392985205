import { createSlice } from "@reduxjs/toolkit";

const currencyInfoSlice = createSlice({
  name: "currencyInfo",
  initialState: {
    current: {
      id: "233",
      dail_code: "971",
      country_code: "AE",
      currency_name: "UAE Dirham",
      currency_code: "AED",
      country_name: "United Arab Emirates",
      exchange_rate: 1,
      currency: null,
      is_visible: true,
      createdAt: "2024-01-27T08:22:58.666Z",
      updatedAt: "2024-01-27T10:00:50.171Z",
    },
    currencyInfo: [
      {
        id: "233",
        dail_code: "971",
        country_code: "AE",
        currency_name: "UAE Dirham",
        currency_code: "AED",
        country_name: "United Arab Emirates",
        exchange_rate: 1,
        currency: null,
        is_visible: true,
        createdAt: "2024-01-27T08:22:58.666Z",
        updatedAt: "2024-01-27T10:00:50.171Z",
      },
    ],
  },
  reducers: {
    storeCurrencyInfo: (state, action) => {
      state.currencyInfo = action.payload;
    },
    changeCurrentCurrency: (state, action) => {
      state.current = action.payload;
    },
  },
});
export const { storeCurrencyInfo , changeCurrentCurrency} = currencyInfoSlice.actions;

export const selectCurrencyInfo = (state: any) => state.currencyInfo;
export default currencyInfoSlice.reducer;
