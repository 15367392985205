import React from "react";
import { Col, Row } from "react-bootstrap";

function Section2() {
  return (
    <div className="app-ProfileScreen-Section2">
      <div className="app-ProfileScreen-Section1-Txt2">Bank details</div>
      <br />
      <Row>
        <Col md={6} xs={12}>
          <div className="app-ProfileScreen-Section2-box1">
            <div className="app-ProfileScreen-Section2-Box2">
              Name : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                LUCKYSTAR GOLD TRADING L.L.C
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              A/C NO : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                0373180410001
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              IBAN : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                AE510400000373180410001
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              SWIFT CODE : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                NRAKAEAKKXXX
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              BANK : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                RAK BANK
              </span>
            </div>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="app-ProfileScreen-Section2-box1">
            <div className="app-ProfileScreen-Section2-Box2">
              Name : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                LUCKYSTAR GOLD TRADING L.L.C
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              A/C NO : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                80309100026813
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              IBAN : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                AE070150080309100026813
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              SWIFT CODE : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                BMISAEAAXXX
              </span>
            </div>
            <div className="app-ProfileScreen-Section2-Box2">
              BRANCH : &nbsp;&nbsp;
              <span className="app-ProfileScreen-UserForm-Box2-txt1">
                BANQUE MISR
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Section2;
