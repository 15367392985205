import { Card } from "antd";
import { Col, Row } from "react-bootstrap";
import "../styles.scss";

import { useTranslation } from "react-i18next";

export default function IntroductionSection(props: any) {
  const { t } = useTranslation();
  return (
    <div className="app-SipScreen-IntroductionSection">
      <div className="app-SipScreen-txt3">{t("What_sip")}</div>
      <div className="app-SipScreen-txt4">{t("sip_description")}</div>
      <br />{" "}
      <div className="app-SipScreen-txt3">{t("secure_your_future_sip")}</div>
      <div className="app-SipScreen-txt4">{t("gold_sip_introduction")}</div>
      <br />
      <div className="app-SipScreen-txt3">{t("features")}</div>
      <Row>
        <Col md={6} xs={12} style={{ marginBottom: "20px" }}>
          <Card className="app-SipScreen-Introduction-feature-box">
            <div className="app-SipScreen-txt5">
              {t("customize_your_own_plan")}
            </div>
            <div className="app-SipScreen-txt6">
              {t("craft_your_financial_path")}
            </div>
          </Card>
        </Col>
        <Col md={6} xs={12} style={{ marginBottom: "20px" }}>
          <Card className="app-SipScreen-Introduction-feature-box ">
            <div className="app-SipScreen-txt5">
              {" "}
              {t("flexible_deposit_options")}
            </div>
            <div className="app-SipScreen-txt6">
              {t("deposit_at_your_convenience")}
            </div>
          </Card>
        </Col>
        <Col md={6} xs={12} style={{ marginBottom: "20px" }}>
          <Card className="app-SipScreen-Introduction-feature-box">
            <div className="app-SipScreen-txt5">
              {t("withdraw_gold_anytime")}
            </div>
            <div className="app-SipScreen-txt6">
              {t("withdraw_flexibility")}
            </div>
          </Card>
        </Col>
        <Col md={6} xs={12} style={{ marginBottom: "20px" }}>
          <Card className="app-SipScreen-Introduction-feature-box">
            <div className="app-SipScreen-txt5">
              {t("secure_and_trustworthy")}
            </div>
            <div className="app-SipScreen-txt6">
              {t("secure_and_trustworthy_description")}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
