import React, { useEffect, useState } from "react";
import "./styles.scss";

import { Col, Row } from "react-bootstrap";
import { Button, Flex, Form, Modal, notification, Popconfirm } from "antd";
import TextArea from "antd/es/input/TextArea";

import { changeMetalTypeFormat } from "../../../shared/helpers/functions";

import moment from "moment";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";

import { GET, POST, PUT } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import HistoryDataTable from "./components/historyDataTable";
import AppPageHeader from "../../routes/appPageHeader";
import AddAmountModal from "./components/addAmountModal";

function SipDetails() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [notificationApi, contextHolder] = notification.useNotification();

  const [sip, setSip] = useState<any>({});
  const [refresh, setRefresh] = useState(false);
  const [modal, setOpenModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const param = useParams();
  const [form] = useForm();

  useEffect(() => {
    getSip();
  }, [refresh]);
  const getSip = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.SIP + param?.id, null);
      console.log("response", response);
      if (response?.status) {
        setSip(response?.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      const response: any = await PUT(API.SIP_TERMINATE + param?.id, {});
      if (response?.status) {
        notificationApi.success({
          message: `Termination Successful: ${
            response?.data?.weight
          } grams of ${changeMetalTypeFormat(
            response?.data?.metal_type
          )} have been credited to your account.`,
        });
        setRefresh(!refresh);
        setOpenConfirm(false);
      } else {
        notificationApi.error({ message: response?.message });
      }
    } catch (error) {
    } finally {
      setConfirmLoading(false);
    }
  };

  function paymentDateHelper() {
    const payment_date = sip?.payment_date;
    const date = moment(payment_date);

    if (payment_date) {
      if (sip?.interval === "daily") {
        return `Your Payment Date is daily`;
      }
      if (sip?.interval === "weekly") {
        return `Your Payment Date is Every ${date.format("dddd")}`;
      }

      if (sip?.interval === "monthly") {
        return `Your Payment Date is ${date.format("Do")} of Every Month`;
      }

      if (sip?.interval === "yearly") {
        return `Your Payment Date is ${date.format("Do")} / ${date.format(
          "MMMM"
        )} of
            Every Year`;
      }
    }
  }

  const handleCancel = async (val: any) => {
    try {
      setConfirmLoading(true);
      let obj = {
        id: param?.id,
        comment: val?.comment,
      };
      const response: any = await POST(API.SIP_CANCELED, obj);
      console.log("response", response);
      if (response?.status) {
        notificationApi.success({
          message: `Canceled Successfully`,
        });
        setRefresh(!refresh);
        setOpenConfirm(false);
      } else {
        notificationApi.error({ message: response?.message });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setConfirmLoading(false);
    }
  };
  return (
    <div>
      {contextHolder}
      <AppPageHeader title={"Sip Details"} />
      <div className="app-SipScreen-SipDetails">
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "20px",
          }}
        >
          <div className="app-SipScreen-SipDetails-box1"></div>
          <div className="app-SipScreen-SipDetails-txt1">
            {t("my_sip_details")}
          </div>
        </div>
        <br />
        <div className="app-SipScreen-SipDetails-Box2">
          <Row>
            <>
              {sip?.status === "pending" ? (
                <>
                  <Row>
                    <Col md={6} xs={12}>
                      <div className="app-SipScreen-txt5">
                        SIP Request Pending, <br />
                        <br />
                        <span className="app-SipScreen-txt6">
                          Your Systematic Investment Plan (SIP) request is
                          currently pending. We are processing your request and
                          will notify you once it is completed. Thank you for
                          your patience.
                        </span>
                      </div>
                      <Button
                        className="app-SIPScreen-Buttons "
                        onClick={() => setOpenModal(!modal)}
                      >
                        Cancel Sip Request
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : sip?.status == "accepted" ? (
                <>
                  <Col md={6}>
                    <div className="app-SipScreen-txt5">
                      SIP Request Accepted , <br />
                      <br />
                      <span className="app-SipScreen-txt6">
                        Your Systematic Investment Plan (SIP) request has been
                        successfully accepted. Thank you for choosing our
                        services. You will receive further details via email
                        shortly.
                      </span>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <Popconfirm
                        title={
                          <div style={{ color: "#ffd835" }}>
                            {t("terminate_sip")}
                          </div>
                        }
                        description={
                          <div style={{ color: "black" }}>
                            {t("are_you_sure_termination")}
                          </div>
                        }
                        okText={t("yes")}
                        cancelText={t("no")}
                        onConfirm={handleOk}
                        okButtonProps={{
                          loading: confirmLoading,
                          className: "customBtn",
                        }}
                        cancelButtonProps={{
                          style: { color: "#066949" }, // Adjust this to your primary color
                        }}
                        onCancel={() => setOpenConfirm(false)}
                        placement="bottom"
                      >
                        <Button
                          onClick={() => setOpenConfirm(!openConfirm)}
                          disabled={sip?.isExpired}
                          className="app-SIPScreen-Buttons "
                        >
                          {t("terminate_sip")}
                        </Button>
                      </Popconfirm>
                      <div style={{ width: 20 }} />
                      <Button
                        className="app-SIPScreen-Buttons "
                        onClick={() => setIsModalOpen(true)}
                        disabled={sip?.isExpired}
                      >
                        {t("add_amount")}
                      </Button>
                    </div>
                  </Col>
                </>
              ) : sip?.status === "cancelled" ? (
                <Col md={6} className="d-flex ">
                  <div className="app-SipScreen-txt5">Sip is Canceled</div>
                </Col>
              ) : sip?.status === "rejected" ? (
                <Col md={6}>
                  <div className="app-SipScreen-txt5">
                    SIP Request Rejected , <br />
                    <br />
                    <span className="app-SipScreen-txt6">
                      We regret to inform you that your Systematic Investment
                      Plan (SIP) request has been rejected. Please contact our
                      support team for further assistance and information
                      regarding the next steps.
                    </span>
                  </div>
                </Col>
              ) : null}
            </>
          </Row>
        </div>
        <br />
        <div className="app-SipScreen-SipDetails-Box2">
          <Row>
            <Col md={6}>
              <div style={{ marginBottom: 20 }}>
                <span className="app-SipScreen-txt6  ">
                  {t("metal_type")} :{" "}
                </span>
                <span className="app-Sipscreen-DataTable-txt1">
                  {changeMetalTypeFormat(sip?.metal_type)}
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ marginBottom: 20 }}>
                <span className="app-SipScreen-txt6  ">
                  {t("payment_date")} :
                </span>
                <span className="app-Sipscreen-DataTable-txt1">
                  &nbsp;
                  {paymentDateHelper()}
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ marginBottom: 20 }}>
                <span className="app-SipScreen-txt6  ">
                  {t("accumulated_weight")} :{" "}
                </span>
                <span className="app-Sipscreen-DataTable-txt1">
                  {sip?.weight?.toFixed(2)} {t("gram")}
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ marginBottom: 20 }}>
                <span className="app-SipScreen-txt6  ">
                  {t("target_weight")} :{" "}
                </span>
                <span className="app-Sipscreen-DataTable-txt1">
                  {sip?.target_weight?.toFixed(2)} {t("gram")}
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ marginBottom: 20 }}>
                <span className="app-SipScreen-txt6  ">{t("tenure")} : </span>
                <span className="app-Sipscreen-DataTable-txt1">
                  {sip?.tenure} {t("years")}
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ marginBottom: 20 }}>
                <span className="app-SipScreen-txt6  ">
                  {t("payment_interval")} :{" "}
                </span>
                <span className="app-Sipscreen-DataTable-txt1">
                  {/* {sip?.interval} */}
                  {sip?.interval}
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ marginBottom: 20 }}>
                <span className="app-SipScreen-txt6  ">
                  {t("created_date")} :{" "}
                </span>
                <span className="app-Sipscreen-DataTable-txt1">
                  {moment(sip?.createdAt).format("DD/MM/YYYY")}
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ marginBottom: 20 }}>
                <span className="app-SipScreen-txt6  ">{t("sip_open")} : </span>
                <span className="app-Sipscreen-DataTable-txt1">
                  {sip?.isExpired ? t("no") : t("yes")}
                </span>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ marginBottom: 20 }}>
                <span className="app-SipScreen-txt6  ">SIP Status : </span>
                <span className="app-Sipscreen-DataTable-txt1">
                  {sip?.status == "accepted" ? "Accepted" : sip?.status}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <div className="app-SipScreen-SipDetails-Box2">
          <div className="app-SipScreen-txt5">{t("sip_history")}</div>
          <br />
          <div>
            <HistoryDataTable sipId={sip?.id} refresh={refresh} />
          </div>
        </div>
      </div>
      <Modal
        onOk={form.submit}
        okText={t("ok")}
        cancelText={t("cancel")}
        open={modal}
        okButtonProps={{ className: "customBtn" }}
        cancelButtonProps={{ className: "customBtn" }}
        onCancel={() => {
          setOpenModal(false);
          form.resetFields();
        }}
      >
        <div>{t("are_you_sure_cancel_trade")}</div>
        <br />

        <Form onFinish={handleCancel} form={form}>
          <Form.Item
            label={t("reason")}
            name="comment"
            rules={[{ required: true, message: t("please_enter_your_reason") }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
      <AddAmountModal
        setRefresh={() => setRefresh(!refresh)}
        sip={sip}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default SipDetails;
