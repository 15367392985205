import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./styles.scss";
import { IoCloseOutline } from "react-icons/io5";

import Delete from "../../assets/images/DeleteIcon.png";
import { Button, message } from "antd";
import WebsiteHeader from "../../components/websiteHeader";
import { useNavigate, useParams } from "react-router-dom";
function DeleteUser() {
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const DeleteUser = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      message.success("Deletion request sent successfully");
    }, 5000);
  };
  return (
    <div>
      <WebsiteHeader />
      <div className="DeleteScreen">
        <div className="DeleteScreen-Box1">
          <div
            style={{
              width: "100%",
              textAlign: "end",
            }}
          >
            <IoCloseOutline
              size={30}
              color="#fff"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="DeleteScreen-Box2">
            <img src={Delete} />
          </div>

          <div className="DeleteScreen-Box3">
            Are you sure you want to delete your account permanently?
          </div>
          <div className="DeleteScreen-Box4">
            By doing this,we will send a request to admin ,after admin approval
            your account will be deleted permanently and you will not be able to
            recover your account anymore{" "}
          </div>
          <div className="DeleteScreen-Box5">
            <Button style={{ color: "#000" }} onClick={() => navigate("/")}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => DeleteUser()}
              loading={loading}
            >
              Delete account
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteUser;
