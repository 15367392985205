import { Button, DatePicker, Input, notification, Pagination } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import API from "../../../config/api";
import useDebounce from "../../../shared/helpers/useDebounce";
import { DELETE, GET } from "../../../utlis/apiCalls";
import PageHeader from "../../routes/pageHeader";
import DataTable from "./components/dataTable";

function Products() {
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);
  const [products, setProducts] = useState<any>();
  const [date, setDate] = useState({
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    getAllTrade();
  }, [page, useDebounce(searchValue, 1500), date, pageSize]);

  const { RangePicker } = DatePicker;

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePageSize = (current: number, page: number) => {
    setPage(current);
    setPageSize(page);
  };

  const getAllTrade = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.PRODUCTS_ALL +
          `?order=DESC&page=${page}&take=${pageSize}&search=${searchValue}`,
        {}
      );
      if (response?.status) {
        setProducts(response.data.data);
        setMeta(response?.data?.meta);
      } else {
        setProducts([]);
        setMeta([]);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };

  const handleRefresh = () => {
    setSearchValue("");
    setPage(1);
    getAllTrade();
  };

  const DateChange = (dates: any, dateStrings: any) => {
    setDate({
      fromDate: dateStrings[0],
      toDate: dateStrings[1],
    });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const deleteProduct = async (id: number) => {
    try {
      setIsLoading2(true);
      const response: any = await DELETE(`${API.PRODUCTS}/${id}`);
      if (response?.status) {
        getAllTrade();
        notificationApi.success({
          message: "Success",
          description: response.message || "Category deleted successfully",
        });
      } else {
        notificationApi.error({
          message: "Error",
          description: response.message || "Failed to delete category",
        });
      }
    } catch (error: any) {
      notificationApi.error({
        message: "Error",
        description: error?.message || "Failed to delete category",
      });
    } finally {
      setIsLoading2(false);
    }
  };
  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Products"}>
          <div className="d-flex">
            <RangePicker onChange={DateChange} disabledDate={disabledDate} />
            <div style={{ width: 20 }} />
            <Input
              style={{ width: "200px" }}
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              prefix={<IoSearchOutline size={20} color="gray" />}
              placeholder="Search here.."
              suffix={
                <IoIosClose
                  size={20}
                  color="grey"
                  cursor={"pointer"}
                  onClick={() => setSearchValue("")}
                />
              }
            />
            <div style={{ width: 20 }} />
            <Button type="primary" onClick={handleRefresh}>
              Refresh
            </Button>
            <Button
              type="primary"
              className="ml-4"
              onClick={() => navigate("/admin/products/create")}
            >
              Create
            </Button>
          </div>
        </PageHeader>
        <div className="overFlow-Box1">
          <DataTable
            isLoading={isLoading}
            allTrade={products}
            isLoading2={isLoading2}
            deleteCategory={(id: number) => deleteProduct(id)}
          />
          <Pagination
            current={page || 1}
            pageSize={pageSize || 10}
            total={meta?.itemCount || 0}
            defaultCurrent={1}
            responsive={true}
            onShowSizeChange={handlePageSize}
            defaultPageSize={pageSize || 10}
            disabled={false}
            hideOnSinglePage={pageSize < 10 ? true : false}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
        </div>
      </Container>
    </div>
  );
}

export default Products;
