const API = {
  // BASE_URL: "https://server.bairuhatech.com/lsggold/", //hostinger-old
  BASE_URL: "https://digigold-server.semikolen.com/", //scaleway
  // BASE_URL: "http://localhost:8012/",

  GGL_TOKEN: "AIzaSyBS17KiJVZWcPh0dLI9KKqVTcnUYwYp3PQ",
  PDF_GENERATE_URL: `https://pdf.taxgoglobal.com/getPdf`,
  FILE_PATH:
    "https://digigold.luckystargold.com/static/media/logo-2.df1c90852f4ce5ee2f6e.png",
  COLOR: "#c99000",

  // LIVE_RATE - SOCKET
  GET_LIVERATE: "wss://digigold-server.semikolen.com/liverate", // socket io connection

  GET_CLOSING_RATE: "liverate/closingRate", // Method: GET

  LOGIN: "users/register",
  USER_DETAILS: "users/getUsers",
  USER_KYC_UPLOADED: "users/getuserKyc",
  UPDATE_KYC_BY_ADMIN: "kyc/upateAdmin/",
  USER_EDIT: "users/",
  USER_EDIT_REMOVE_PROFILE: "users/removeprofile/",
  CUSTOMER_DETAILS: "users/customer/",
  //live Rate
  LIVE_RATE: "liveRate/getRateWithVarient",

  ADD_KYC: "kyc/addKyc/",
  GET_KYC_BY_ID: "kyc/getKyc/",
  GET_KYC_BY_STATUS: "kyc/getKycstatus/",
  UPDATE_ISSUED: "kyc/",
  IMAGE_UPLOADER: "img_compress/compress",
  KYC_UPLOADED_USERS: "users/getuserKyc",

  NOTIFICATION_ALL: "notification/userAll/",
  NOTIFICATION_UPATE: "notification/read/",
  NOTIFICATION_UPATE_ALL: "notification/readAll/",

  //contacts
  CONTACT_ALL: "contact/all",
  CONTACT_CREATE: "contact/create",

  //script
  SCRIPT_ALL: "script/all", //get all scripts
  SCRIPT: "script/", //get all scripts
  SCRIPT_UPDATE: "script/update/", //update all Script variants, discount,premium

  //trading
  TRADING_ADD: "trading/create",
  TRADING_ADD_ADMIN: "trading/createWithoutApproval",
  TRADING_LIST: "trading/all",
  TRADING_DETAILS: "trading/",
  TRADING_PROCESS: "trading/",
  TRADING_USERS_LIST: "trading/userAll/",
  TRADING_CANCEL: "trading/cancelTrade",

  //history
  TRADE_HISTORY: "history/tradeHistory/",

  //wallet
  GIFT_SOMEONE: "wallet/giftSomeone",
  GET_WALLET: "wallet/",
  UPDATE_WALLET: "wallet/update/",
  FETCH_WALLET: "walletRequest/requestsAll",
  WALLET_FETCH_ONE: "walletRequest/",

  //Currency Info
  CURRENCY_LIST: "currencyinfo/all", // for admin
  CURRENCY_UPDATE: "currencyinfo/update/",
  CURRENCY_USER_LIST: "currencyinfo/user/all", // for user

  //SIP
  SIP: "sip/", // get single sip
  SIP_ADD: "sip/create",
  SIP_USERS_LIST: "sip/userAll/",
  SIP_UPDATE: "sip/",
  SIP_ALL: "sip/all",
  SIP_TERMINATE: "sip/terminate/",
  SIP_STATUS: "sip/status/",
  SIP_CANCELED: "sip/cancel",

  // SIP HISTORY
  SIP_HISTORY: "sipHistory/",
  SIP_HISTORY_ALL: "sipHistory",
  SIP_HISTORY_SIP_BY_ID: "sipHistory/sip/",

  //redeem
  REDEEM: "redeem/",
  REDEEM_CREATE: "redeem/create",
  REDEEM_LIST: "redeem/all",
  REDEEM_UPDATE: "redeem/update",

  //walletHistory
  WALLET_HISTORY_ALL: "walletHistory/userAll/",

  //walletRequest
  WALLET_REQUEST: "walletRequest/requestcash-wallet",
  WALLET_REQUEST_BY_USER_ID: "walletRequest/user/",
  WALLET_REQUEST_ACCEPT: "walletRequest/approve-cashWallet/",

  //transaction log
  TRANSACTION_LOG_ALL: "transactionLog/userAll/",
  SIP_LOG: "transactionLog/sipHistory/",
  //google location
  GET_LOCATION: "https://maps.googleapis.com/maps/api/geocode/json",
  GET_AUTOCOMPLETE:
    "https://maps.googleapis.com/maps/api/place/autocomplete/json",

  // SMS
  REQUEST_OTP: "sms/request-otp", // Method: POST
  VERIFY_OTP: "sms/verify-otp", // Method: POST
  // update phone
  UPDATE_PHONE_REQUEST_OTP: "sms/phone/verify-otp", // Method: POST
  UPDATE_USER_PHONE: "sms/phone/",
  //STRIPE
  PAYMENT_CREATE: "payment/stripe", // Method: POST
  PAYMENT_RETRIEVE: "payment/retrievePayment", // Method: POST

  // INVOICE
  GENERATE_iNVOICE: "invoice/add-sales-invoice",

  //PRODUCTS
  PRODUCTS_ALL: "products/all",
  PRODUCTS_CREATE: "products/create",
  PRODUCTS: "products",

  //CATEGORY
  CATEGORY: "category",
  CATEGORY_ALL: "category/all",
  CATEGORY_CREATE: "category/create",

  //ORDER
  ORDER_ALL: "orders/all",
  ORDER_DEATAILS: "orders/deatails/",
  ORDER_TYPES: "orders/types/",
  ORDER_STATUS_LIST: "orders/order_types",
  ORDER_STATUS: "orders/status/",
};
export default API;
