import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Modal, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CiMoneyBill } from "react-icons/ci";
import { GiScales } from "react-icons/gi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoRadioButtonOff } from "react-icons/io5";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentTypeModal from "../../../../app/screens/homescreen/components/paymentTypeModal";
import WithDrawTypeModal from "../../../../app/screens/homescreen/components/withdrawTypeModal";
import API from "../../../../config/api";

import {
  changeMetalTypeFormat,
  handleLiveRateByType,
} from "../../../../shared/helpers/functions";
import { POST } from "../../../../utlis/apiCalls";
import LiveRateCard from "../../dashboard/components/liveRate";
import "../styles.scss";
export default function OpenModal(props: any) {
  console.log("==props==",props)
  const navigation = useNavigate();

  const [metal_type, setMetalType] = useState("gold_995");
  const [form] = useForm();
  const [type, setType] = useState("buy");
  const [isLoading, setIsLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const currentInfo = useSelector((state: any) => state?.currencyInfo?.current);

  const liveRate = useSelector((state: any) => state.liveRate);
  const [activeField, SetActiveField] = useState<string>("amount");
  const [isFlipped, setIsFlipped] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpen2, setIsModalOpen2] = useState<boolean>(false);

  const handleIconClick = () => {
    setIsFlipped(!isFlipped);
  };

  const handleSubmit = (val: any) => {
    if (type === "Sell") {
      setIsModalOpen2(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const weightToAmount = () => {
    const weight = form?.getFieldValue("weight");
    const metalCapital = changeMetalTypeFormat(metal_type)?.toUpperCase();

    const metalsData = liveRate?.current?.find(
      (item: any) => item?.metal === metalCapital
    );

    const totalLiveAmount = handleLiveRateByType(type, metalsData);
    const amount = totalLiveAmount * weight * currentInfo?.exchange_rate;
    if (amount < 0) {
      form.setFieldValue("amount", 0);
    } else {
      form.setFieldValue("amount", Number(amount.toFixed(2)));
    }
  };
  const amountToWeight = () => {
    const amount = form?.getFieldValue("amount");
    const metalCapital = changeMetalTypeFormat(metal_type)?.toUpperCase();
    const metalsData = liveRate?.current?.find(
      (item: any) => item?.metal === metalCapital
    );
    const totalLiveAmount = handleLiveRateByType(type, metalsData);
    const weight = amount / (totalLiveAmount * currentInfo?.exchange_rate);
    if (weight < 0) {
      form.setFieldValue("weight", 0);
    } else {
      form.setFieldValue("weight", Number(weight.toFixed(3)));
    }
  };

  const onFinish = async () => {
    let Type = form.getFieldValue("payment_type");
    try {
      setIsLoading(true);
      const obj = {
        type: type?.toLowerCase(),
        metal_type: metal_type,
        user_id: props?.userId,
        amount: Number(
          (form.getFieldValue("amount") / currentInfo?.exchange_rate).toFixed(2)
        )
      };
      let response: any = await POST(API.TRADING_ADD, obj);
      if (Type === "card") {
        console.log("newww");
        navigation("/auth/checkoutScreen", {
          state: {
            paid_module: "trade",
            module_id: response?.data?.trading?.id,
          },
        });
      } else {
        if (response?.status) {
          notificationApi.success({
            message: "your_trade_successfully_placed",
            duration: 6,
          });
          console.log(1);
          form.resetFields();
        } else {
          notificationApi.error({
            message: response?.message,
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      {contextHolder}
      <Modal
        open={props?.isOpen}
        footer={false}
        onCancel={props?.close}
        width={700}
        closable={false}
      >
        <Form.Item name={"payment_type"} style={{ display: "none" }} />
        <div
          className="admin-CustomerScreen-txt2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Purchase
          <CloseOutlined onClick={props?.close} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LiveRateCard />
        </div>
        <br />
        <div style={{ padding: "0px 25px 0px 25px" }}>
          <div className="app-HomeScreen-txt7">Choose Type</div>
          <div className="app-HomeScreen-tabButton3 d-flex flex-row justify-between gap-4">
            {["buy", "sell"]?.map((item: any) => {
              return (
                <div
                  className={
                    type === item
                      ? "adminapp-HomeScreen-tabButton4-active "
                      : "adminapp-HomeScreen-tabButton4"
                  }
                  key={item}
                  onClick={() => setType(item)}
                >
                  <div>{changeMetalTypeFormat(item)}</div>
                  <div className="d-none d-sm-block">
                    {type === item ? (
                      <IoIosCheckmarkCircle color="#fff" size={25} />
                    ) : (
                      <IoRadioButtonOff color="#c99000" size={25} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="app-HomeScreen-txt7">Choose Metal Type</div>
          <div className="app-HomeScreen-tabButton3 d-flex flex-row justify-between gap-4">
            {["gold_995", "gold_9999", "silver_999"]?.map((item: any) => {
              return (
                <div
                  className={
                    metal_type === item
                      ? "adminapp-HomeScreen-tabButton4-active "
                      : "adminapp-HomeScreen-tabButton4 "
                  }
                  key={item}
                  onClick={() => setMetalType(item)}
                >
                  <div>{changeMetalTypeFormat(item)}</div>
                  <div className="d-none d-sm-block">
                    {metal_type === item ? (
                      <IoIosCheckmarkCircle color="#fff" size={25} />
                    ) : (
                      <IoRadioButtonOff color="#c99000" size={25} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <Form
            form={form}
            name="create Trade"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Row>
              <Col sm={5} xs={12}>
                <div>
                  {isFlipped
                    ? "weight(g)"
                    : `amount (${currentInfo?.currency_code})`}
                </div>
                <Form.Item
                  name={isFlipped ? "weight" : "amount"}
                  rules={[
                    {
                      required: true,
                      message: `${
                        isFlipped
                          ? "Please Enter Your Weight"
                          : "Please Enter Your Amount"
                      }!`,
                    },
                    {
                      min: 0.1,
                      type: "number",
                      message: `${
                        isFlipped
                          ? "Weight must not be less than zero"
                          : "Amount must not be less than zero"
                      } !`,
                    },
                  ]}
                >
                  <InputNumber
                    type="number"
                    prefix={
                      <div className="adminapp-HomeScreen-box8">
                        {isFlipped ? (
                          <GiScales size={25} />
                        ) : (
                          <CiMoneyBill size={30} />
                        )}
                      </div>
                    }
                    className="adminapp-HomeScreen-input "
                    style={{ width: "100%", padding: 4 }}
                    size="large"
                    placeholder={isFlipped ? "weight" : "amount"}
                    onChange={isFlipped ? weightToAmount : amountToWeight}
                    onFocus={() =>
                      SetActiveField(isFlipped ? "weight" : "amount")
                    }
                  />
                </Form.Item>
              </Col>
              <Col sm={2} xs={2} className="d-none d-sm-block">
                <div className="app-HomeScreen-txt7 "></div>
                <div
                  className="adminapp-HomeScreen-box10"
                  onClick={() => {
                    handleIconClick();
                  }}
                >
                  <LiaExchangeAltSolid className="adminflipIcon" />
                </div>
              </Col>
              <Col sm={5} xs={10}>
                <div>
                  {isFlipped
                    ? `amount (${currentInfo?.currency_code})`
                    : `weight(g)`}
                </div>
                <Form.Item
                  name={isFlipped ? "amount" : "weight"}
                  rules={[
                    {
                      required: true,
                      message: ` ${
                        isFlipped
                          ? "Please Enter Your Amount"
                          : "Please Enter Your Weight"
                      }!`,
                    },
                    {
                      min: 0.1,
                      type: "number",
                      message: `${
                        isFlipped
                          ? "Amount must not be less than zero"
                          : "Weight must not be less than zero"
                      } !`,
                    },
                  ]}
                >
                  <InputNumber
                    type="number"
                    prefix={
                      <div className="adminapp-HomeScreen-box8">
                        {isFlipped ? (
                          <CiMoneyBill size={30} />
                        ) : (
                          <GiScales size={25} />
                        )}
                      </div>
                    }
                    className="adminapp-HomeScreen-input"
                    style={{ width: "100%", padding: 4 }}
                    size="large"
                    placeholder={isFlipped ? "amount" : "weight"}
                    onChange={isFlipped ? amountToWeight : weightToAmount}
                    onFocus={() =>
                      SetActiveField(isFlipped ? "amount" : "weight")
                    }
                  />
                </Form.Item>
              </Col>
              <Col sm={2} xs={2} className="d-xs-block d-sm-none">
                <div
                  className="app-HomeScreen-box10 "
                  style={{
                    minWidth: "38px",
                    marginLeft: "-17px",
                    width: "100%",
                  }}
                  onClick={() => {
                    handleIconClick();
                  }}
                >
                  <LiaExchangeAltSolid className="flipIcon" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={7} xs={12}>
                <div className="app-HomeScreen-txt7 ">
                  {"24k, 999.9 purity guaranteed by MMTC-PAMP"}
                </div>
              </Col>
              <Col sm={5} xs={12}>
                <Button
                  block
                  size="large"
                  htmlType={"submit"}
                  loading={isLoading}
                  style={{ height: 50, marginTop: 10 }}
                >
                  {type === "buy" ? "Buy" : "Sell"} {"now"}
                </Button>
              </Col>
            </Row>
            <PaymentTypeModal
              admin={true}
              userId={props?.userId}
              currentInfo={currentInfo}
              open={isModalOpen}
              form={form}
              onFinish={() => onFinish()}
              onCancel={() => setIsModalOpen(false)}
              color={"gray"}
            />
            <WithDrawTypeModal
              admin={true}
              userId={props?.userId}
              open={isModalOpen2}
              form={form}
              onFinish={() => onFinish()}
              onCancel={() => setIsModalOpen2(false)}
              color={"gray"}
            />
          </Form>
        </div>
      </Modal>
    </div>
  );
}
