import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import liveRateSlice from "./liveRateSlice";
import currencyInfoSlice from "./currencyInfoSlice";
import languageSlice from "./languageSlice";
import themeSlice from "./themeSlice";

const Slices = combineReducers({
  auth: authSlice,
  liveRate:liveRateSlice,
  currencyInfo:currencyInfoSlice,
  Language: languageSlice,
  theme:themeSlice,
});
export default Slices;
