import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";
import companylogo from "../../../assets/images/brand logo.png";
import goldImage from "../../../assets/images/bg.png";

function Section4() {
  const { t } = useTranslation();
  const [active, setActive] = useState("gold");
  return (
    <div className="homeScreen-section4">
      <Container>
        <Row>
          <Col sm={5} xs={12}>
            <Fade>
              <div className="homeScreen-section4IMG">
                {active === "gold" ? (
                  <img
                    src={goldImage}
                    alt="DigitalImage"
                    style={{ objectFit: "cover" }}
                  />
                ) : (
                  <img src={companylogo} alt="DigitalImage" />
                )}
              </div>
            </Fade>
          </Col>
          <Col sm={7} xs={12}>
            <div className="homeScreen-text10">
              <span className="homeScreen-text2">{t("digital")}</span>{" "}
              {t("to_physical_in_minutes")}
            </div>
            <Fade>
              <div className="FourthScreen-btnCover">
                <div
                  className={`cursor-pointer ${
                    active === "gold"
                      ? "FourthScreen-btnActive"
                      : "FourthScreen-unactive"
                  }`}
                  onClick={() => setActive("gold")}
                >
                  {t("Gold")}
                </div>
                <div
                  className={`cursor-pointer ${
                    active === "jewellery"
                      ? "FourthScreen-btnActive"
                      : "FourthScreen-unactive"
                  }`}
                  onClick={() => setActive("jewellery")}
                >
                  {t("Jewellery")}
                </div>
              </div>
            </Fade>
            <br /> <br />
            {active === "gold" ? (
              <div>
                <div className="homeScreen-text8">
                  {t("24K_Gold_Coins")}
                  {t("your_doorstep")}
                </div>
                <div className="homeScreen-text11">
                  {t("Convert_your_digital")}
                  {t("nominal_minting")}
                  {t("free_insurance")}
                  {t("you_safely")}
                </div>
              </div>
            ) : (
              <div>
                <div className="homeScreen-text8">{t("Redeem_Offline")}</div>
                <div className="homeScreen-text11">
                  {t("Redeem_by_sharing")}
                  {t("CaratLane_store")}
                  {t("your_SafeGold_balance")}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Section4;
